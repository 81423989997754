import React from 'react';

import OhsMfaVerifySteps from './verifystepcontent/OhsMfaVerifySteps';
import useOhsMfaEnrollStepsHook from '../hooks/OhsMfaStepsHook';

interface Props {
  onSuccess: (methodId?: string) => void;
  setCurrentStep?: React.Dispatch<React.SetStateAction<number>>;
}
function OhsMfaVerifyFlow(props: Props) {
  const { activeStep, setActiveStep } = useOhsMfaEnrollStepsHook();

  React.useEffect(() => {
    setActiveStep(0);
  }, []);

  React.useEffect(() => {
    if (props.setCurrentStep) props?.setCurrentStep(activeStep);
  }, [activeStep]);

  return (
    <OhsMfaVerifySteps
      activeStep={activeStep}
      setActiveStep={setActiveStep}
      onSuccess={props.onSuccess}
    />
  );
}

OhsMfaVerifyFlow.defaultProps = {
  setCurrentStep: undefined,
};

export default OhsMfaVerifyFlow;
