import React from 'react';

import { Box, Divider, ListItem, ListItemProps, ListItemText } from '@mui/material';

import { useAppSelector } from 'app/hooks';
import { getOhsUser } from 'user/userSlice';
import { ModuleType } from 'global-services/constants/OhsObject';
import { getModuleName } from 'user/OhsUserServices';

import { OhsUser } from '../../user/OhsUser';
// import ModuleHomeUrl from './ModuleHomeUrl';
import LeftDrawerModule from './LeftDrawerModule';
import { OhsLeftSideDrawerFreeUserModuleList } from './OhsLeftSideDrawerFreeUserModuleList';
import { returnDefaultModuleRoutesByPermission } from './ModuleHomeRoutes';

const getModuleList = (user: OhsUser | null): Array<LeftDrawerModule> => {
  const moduleList = new Array<LeftDrawerModule>();
  if (user) {
    // const moduleUrl = ModuleHomeUrl[user.tier.type];
    const moduleUrl = returnDefaultModuleRoutesByPermission(user)[user.tier.type];
    Object.keys(moduleUrl).forEach((moduleKey: string) => {
      const config = (user.configs as any)[moduleKey];
      if (config) {
        moduleList.push(
          new LeftDrawerModule(moduleKey, config.TITLE, (moduleUrl as any)[moduleKey])
        );
      }
    });
    moduleList.sort((a, b) => {
      return a.title.localeCompare(b.title);
    });
  }

  return moduleList;
};

export function ListItemLink(props: ListItemProps<'a', { button?: true }>) {
  return <ListItem button component="a" {...props} />;
}

export function OhsLeftSideDrawerModuleList() {
  const user = useAppSelector(getOhsUser);
  const moduleList = getModuleList(user);
  return (
    <div>
      <Box m={2}>
        <p>
          <b>Modules</b>
        </p>
      </Box>
      {user?.tierNum === 1 && (
        <>
          <ListItemLink button key="mobileappadmin" href="/mobileappadmin/home" dense>
            <ListItemText primary="Mobile App Admin" />
          </ListItemLink>
          <ListItemLink button href="/reporting/orgAdminReports" dense>
            <ListItemText primary={getModuleName(user?.configs, ModuleType.BusinessIntelligence)} />
          </ListItemLink>
          <ListItemLink button href="/dashboardbanner/home" dense>
            <ListItemText primary="Dashboard Banner" />
          </ListItemLink>
          <ListItemLink button href="/mfasetting/home" dense>
            <ListItemText primary="MFA Reset" />
          </ListItemLink>
        </>
      )}
      {(user?.tierNum === 1 || user?.tierNum === 2) && (
        <ListItemLink button key="resourcecentre" href="/resourcecentre/home" dense>
          <ListItemText primary="Resource Centre" />
        </ListItemLink>
      )}
      {user?.level === 'paid' &&
        moduleList.map((module) => (
          <ListItemLink button key={module.key} href={module.href} dense>
            <ListItemText primary={module.title} />
          </ListItemLink>
        ))}
      {user?.level === 'free' && <OhsLeftSideDrawerFreeUserModuleList />}

      <Divider />
    </div>
  );
}
