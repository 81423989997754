import React from 'react';

import { makeStyles } from 'tss-react/mui';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import { Box, Grid } from '@mui/material';
import { Button } from 'semantic-ui-react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  OhsDashboardState,
  getOhsDashboard,
  setSelectedFilterRecord,
  toggleTopFilterDrawer,
} from 'dashboard/OhsDashboardSlice';
import { OhsFormLayoutType, OhsFormSettings } from 'global-components/form/services/OhsFormModels';
import { getOhsUser } from 'user/userSlice';
import { getOhsLocalStorage } from 'global-services/OhsDataParse';

import OhsLogoImg from '../OhsLogoImg';
import {
  ActionModuleFolder,
  ContractorModuleFolder,
  ChemicalModuleHomeFolder,
  HumanResourceModuleHomeFolder,
  ReviewPlanModuleFolder,
  SafetyPlanModuleHomeFolder,
  InspectionModuleHomeFolder,
  IncidentModuleHomeFolder,
  TaskModuleHomeFolder,
  DashboardBannerModuleFolder,
  NoticeBoardModuleHomeFolder,
  OnboardingModuleHomeFolder,
  CorporateRiskModuleHomeFolder,
  AssetsModuleFolder,
  CorrespondenceModuleHomeFolder,
  VisitorModuleHomeFolder,
  DocumentsModuleHomeFolder,
} from '../../global-services/constants/OhsConstants';
import { getGlobalFilter, updateModuleFilterAsync } from './OhsTopFilterSlice';
import { GlobalFilterName, OhsFilterList } from './OhsFilterModels';
import OhsTaskFilters from './modulefilters/OhsTaskFilters';
import OhsSafetyPlanFilters from './modulefilters/OhsSafetyPlanFilters';
import OhsInspectionFilters from './modulefilters/OhsInspectionFilters';
import OhsIncidentFilters from './modulefilters/OhsIncidentFilters';
import OhsActionFilters from './modulefilters/OhsActionFilters';
import OhsHumanResourceFilters from './modulefilters/OhsHumanResourceFilters';
import OhsContractorFilters from './modulefilters/OhsContractorFilters';
import OhsReviewPlanFilters from './modulefilters/OhsReviewPlanFilters';
import OhsChemicalFilters from './modulefilters/OhsChemicalFilters';
import OhsDashboardBannerFilters from './modulefilters/OhsDashboardBannerFilters';
import OhsNoticeBoardFilters from './modulefilters/OhsNoticeBoardFilters';
import OhsOnboardingFilters from './modulefilters/OhsOnboardingFilters';
import OhsCorporateRiskFilters from './modulefilters/OhsCorporateRiskFilters';
import OhsAssetsFilters from './modulefilters/OhsAssetsFilters';
import OhsCorrepondenceFilters from './modulefilters/OhsCorrepondenceFilters';
import OhsDocumentsFilters from './modulefilters/OhsDocumentsFilters';
import OhsHumanResourceTrainingFilters from './modulefilters/OhsHumanResourceTrainingFilters';
import { OhsTabKeyTypes } from '../../global-components/tab/OhsTabModels';
import OhsVisitorFilters from './modulefilters/OhsVisitorFilters';
import OhsDocumentWorkplaceFilters from './modulefilters/OhsDocumentWorkplaceFilters';
import OhsDocumentsTemplateFilters from './modulefilters/OhsDocumentsTemplateFilters';
import OhsCorrespondenceExchangeFilters from './modulefilters/OhsCorrespondenceExchangeFilters';

const useStyles = makeStyles()(() => ({
  root: {
    flexGrow: 1,
  },
  fieldWrap: {
    margin: '5px 0 5px 0',
  },
  filterContent: {
    width: 300,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

function OhsTopFilterDrawer() {
  const navigate = useNavigate();
  const dashboard = useAppSelector(getOhsDashboard) as OhsDashboardState;
  const filter = useAppSelector(getGlobalFilter) as OhsFilterList;
  const dispatch = useAppDispatch();
  const user = useAppSelector(getOhsUser);
  const { classes } = useStyles();
  const [searchParams] = useSearchParams();
  const tabKey = searchParams.get('tab');
  const ohsFormSettings = new OhsFormSettings(filter, OhsFormLayoutType.AngularModalForm);

  const resetFilter = () => {
    const defaultFilter = new OhsFilterList(user, tabKey);
    dispatch(updateModuleFilterAsync(defaultFilter));
    dispatch(toggleTopFilterDrawer(false));
    dispatch(setSelectedFilterRecord({}));
    navigate(0);
  };

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    dispatch(toggleTopFilterDrawer(open));
  };

  const modulePath = window.location.pathname.split('/')[1];

  const formSubmit = async (data: any) => {
    const filterData: OhsFilterList = data as OhsFilterList;
    dispatch(toggleTopFilterDrawer(false));
    dispatch(setSelectedFilterRecord({}));
    dispatch(updateModuleFilterAsync(filterData));
  };

  React.useEffect(() => {
    ohsFormSettings.useFormMethods.reset(filter);
  }, [filter]);

  React.useEffect(() => {
    if (!getOhsLocalStorage(GlobalFilterName)) {
      const defaultFilter = new OhsFilterList(user, tabKey);
      dispatch(updateModuleFilterAsync(defaultFilter));
      navigate(0);
    }
  }, [user]);

  return (
    <Drawer anchor="right" open={dashboard.topFilterDrawerToggleOpen} onClose={toggleDrawer(false)}>
      <Box className={classes.filterContent}>
        <Box m={2}>
          <div className={classes.root}>
            <Grid container>
              {modulePath === TaskModuleHomeFolder && (
                <OhsTaskFilters ohsFormSettings={ohsFormSettings} />
              )}
              {modulePath === SafetyPlanModuleHomeFolder && (
                <OhsSafetyPlanFilters ohsFormSettings={ohsFormSettings} />
              )}
              {modulePath === InspectionModuleHomeFolder && (
                <OhsInspectionFilters
                  ohsFormSettings={ohsFormSettings}
                  isOrg={dashboard?.selectedFilterRecord?.isOrg}
                />
              )}
              {modulePath === IncidentModuleHomeFolder && (
                <OhsIncidentFilters ohsFormSettings={ohsFormSettings} />
              )}
              {modulePath === ActionModuleFolder && (
                <OhsActionFilters ohsFormSettings={ohsFormSettings} />
              )}
              {modulePath === AssetsModuleFolder && (
                <OhsAssetsFilters ohsFormSettings={ohsFormSettings} />
              )}
              {/* eslint-disable-next-line no-nested-ternary */}
              {modulePath === CorrespondenceModuleHomeFolder ? (
                window.location.pathname.includes('edit') ? (
                  <OhsCorrespondenceExchangeFilters ohsFormSettings={ohsFormSettings} />
                ) : (
                  <OhsCorrepondenceFilters ohsFormSettings={ohsFormSettings} />
                )
              ) : null}

              {/* eslint-disable-next-line no-nested-ternary */}
              {modulePath === DocumentsModuleHomeFolder ? (
                // eslint-disable-next-line no-nested-ternary
                tabKey === OhsTabKeyTypes.Documents || !tabKey ? (
                  <OhsDocumentsFilters ohsFormSettings={ohsFormSettings} />
                ) : tabKey === OhsTabKeyTypes.Workplace ? (
                  <OhsDocumentWorkplaceFilters ohsFormSettings={ohsFormSettings} />
                ) : (
                  <OhsDocumentsTemplateFilters ohsFormSettings={ohsFormSettings} />
                )
              ) : null}

              {/* eslint-disable-next-line no-nested-ternary */}
              {modulePath === HumanResourceModuleHomeFolder ? (
                tabKey === OhsTabKeyTypes.Training ? (
                  <OhsHumanResourceTrainingFilters ohsFormSettings={ohsFormSettings} />
                ) : (
                  <OhsHumanResourceFilters ohsFormSettings={ohsFormSettings} />
                )
              ) : null}
              {modulePath === ContractorModuleFolder && (
                <OhsContractorFilters ohsFormSettings={ohsFormSettings} />
              )}
              {modulePath === ReviewPlanModuleFolder && (
                <OhsReviewPlanFilters ohsFormSettings={ohsFormSettings} />
              )}
              {modulePath === ChemicalModuleHomeFolder && (
                <OhsChemicalFilters ohsFormSettings={ohsFormSettings} />
              )}
              {modulePath === DashboardBannerModuleFolder && (
                <OhsDashboardBannerFilters ohsFormSettings={ohsFormSettings} />
              )}
              {modulePath === NoticeBoardModuleHomeFolder && (
                <OhsNoticeBoardFilters ohsFormSettings={ohsFormSettings} />
              )}
              {modulePath === CorporateRiskModuleHomeFolder && (
                <OhsCorporateRiskFilters ohsFormSettings={ohsFormSettings} />
              )}
              {modulePath === OnboardingModuleHomeFolder && (
                <OhsOnboardingFilters ohsFormSettings={ohsFormSettings} />
              )}
              {modulePath === VisitorModuleHomeFolder && (
                <OhsVisitorFilters ohsFormSettings={ohsFormSettings} />
              )}
            </Grid>
          </div>
        </Box>

        <Divider />
        <Box m={1}>
          <div className={classes.buttons}>
            <Button onClick={resetFilter} className="ui gray button">
              Reset
            </Button>
            <Box>
              <Button
                onClick={ohsFormSettings.useFormMethods.handleSubmit(formSubmit)}
                className="ui orange button"
              >
                <i className="filter icon" />
                Filter
              </Button>
            </Box>
          </div>
        </Box>
        <Divider />
        <Box sx={{ margin: 'auto', width: '60%' }}>
          <OhsLogoImg style={{ width: '100%' }} />
        </Box>
      </Box>
    </Drawer>
  );
}

export default OhsTopFilterDrawer;
