import React, { useEffect } from 'react';

import { makeStyles } from 'tss-react/mui';
import { FieldValues } from 'react-hook-form';

import { OhsFormSettings } from 'global-components/form/services/OhsFormModels';
import {
  OhsTaskEndDateRecordField,
  OhsTaskStartDateRecordField,
} from 'task/models/OhsTaskRecordField';

import OhsDateField from './OhsDateField';

interface Props {
  id: string;
  ohsFormSettings: OhsFormSettings<FieldValues>;
  startId?: string;
  endId?: string;
}

const useStyles = makeStyles()(() => ({
  dateRangeWrap: {
    border: '1px solid lightgrey',
    borderRadius: '4px',
    padding: '8px',
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
    gap: '10px',
  },
}));

export default function OhsDateRangePicker(props: Props) {
  const { classes } = useStyles();
  const startId = props.startId || OhsTaskStartDateRecordField.key;
  const endId = props.endId || OhsTaskEndDateRecordField.key;

  const start = props.ohsFormSettings.useFormMethods.watch(startId) ?? null;
  const end = props.ohsFormSettings.useFormMethods.watch(endId) ?? null;
  const endDateState = props.ohsFormSettings.useFormMethods.getFieldState(endId);
  useEffect(() => {
    if (start !== null && end !== null) {
      const startDate: Date = new Date(start);
      const endDate: Date = new Date(end);
      if (startDate > endDate) {
        props.ohsFormSettings.useFormMethods.setError(endId, {
          type: 'notEmpty',
          message: 'End date cannot be before the start date.',
        });
      } else {
        props.ohsFormSettings.useFormMethods.setValue(props.id, {
          [startId]: start,
          [endId]: end,
        });
        props.ohsFormSettings.useFormMethods.trigger();
      }
    } else {
      props.ohsFormSettings.useFormMethods.unregister(props.id);
    }
  }, [start, end, props.id, endDateState?.error?.type]);

  return (
    <div className={classes.dateRangeWrap}>
      <OhsDateField
        title={OhsTaskStartDateRecordField.name}
        id={startId}
        required={end !== null}
        ohsFormSettings={props.ohsFormSettings}
      />
      <OhsDateField
        title={OhsTaskEndDateRecordField.name}
        disabled={start === null}
        minDate={{ name: OhsTaskStartDateRecordField.name, date: start }}
        id={endId}
        required={start !== null}
        ohsFormSettings={props.ohsFormSettings}
      />
    </div>
  );
}

OhsDateRangePicker.defaultProps = {
  startId: undefined,
  endId: undefined,
};
