import _ from 'lodash';
import Cookies from 'js-cookie';

import { ModuleType } from 'global-services/constants/OhsObject';
import { OhsApiRequestName, RequestType } from 'global-services/api/OhsApiModels';
import { OhsTier } from 'global-services/constants/tier/OhsTier';
import getMixpanelTracker from 'global-services/mixpanel/OhsMixpanel';
import { OhsLocalStoragePrefix, getLocalStorage } from 'global-services/OhsDataParse';
import { GlobalFilterName } from 'dashboard/topFilter/OhsFilterModels';
import OhsUserDataServices from 'user/OhsUserDataServices';
import { UserDataLocalStorageName } from 'user/OhsUserModels';
import {
  CookiesExpiresDays,
  OhsMultiUserSessionToken,
  OhsSessionToken,
} from 'global-services/constants/OhsStorageNames';

import OhsApiRequest from '../global-services/api/OhsApiRequest';
import { getOhsMultiUserLoginOptionList } from './multi/OhsMultiUserLoginOption';

export const loginByUsernamePassword = (email: string, password: string) => {
  // get Active token of the account and use it as session token
  let userActiveSession;
  let isMultiUser;
  const getUserActiveSessions: any = getLocalStorage('userActiveSessions') ?? [];
  if (getUserActiveSessions && getUserActiveSessions.length > 0) {
    const getExistingToken = getUserActiveSessions.find((item: any) => item.email === email);
    isMultiUser = getExistingToken?.multiUser;
    if (_.has(getExistingToken, OhsSessionToken)) {
      userActiveSession = getExistingToken[OhsSessionToken];
      Cookies.set(OhsSessionToken, getExistingToken[OhsSessionToken], {
        expires: CookiesExpiresDays,
        path: '/',
      });
    }
    if (_.has(getExistingToken, OhsMultiUserSessionToken)) {
      userActiveSession = getExistingToken[OhsMultiUserSessionToken];
      Cookies.set(OhsMultiUserSessionToken, getExistingToken[OhsMultiUserSessionToken], {
        expires: CookiesExpiresDays,
        path: '/',
      });
    }
  }
  return OhsApiRequest(
    RequestType.Admin,
    ModuleType.User,
    OhsApiRequestName.Authenticate,
    {
      email,
      password,
    },
    {
      sessionToken: {
        [isMultiUser ? OhsMultiUserSessionToken : OhsSessionToken]: userActiveSession,
      },
    }
  );
};

export const loginBySSO = (token: string) => {
  return OhsApiRequest(RequestType.Admin, ModuleType.User, OhsApiRequestName.SsoLogin, {
    token,
  });
};

export const InactiveSession = (isMultiUser: boolean) => {
  return OhsApiRequest(
    RequestType.Admin,
    ModuleType.User,
    OhsApiRequestName.AuthenticateInactive,
    {},
    {
      sessionToken: {
        [isMultiUser ? OhsMultiUserSessionToken : OhsSessionToken]: Cookies.get(
          isMultiUser ? OhsMultiUserSessionToken : OhsSessionToken
        ),
      },
    }
  );
};

export const getHomeUrl = (userTier?: OhsTier): string => {
  if (userTier) {
    if (
      userTier.type === 'core.tier.T3' ||
      userTier.type === 'core.tier.T4' ||
      userTier.type === 'core.tier.T5'
    ) {
      return '/task/active';
    }
    return '/admin2/home';
  }
  return '/login';
};
export const removeGlobalFilterAndUserDate = () => {
  localStorage.removeItem(OhsLocalStoragePrefix + GlobalFilterName);
  localStorage.removeItem(OhsLocalStoragePrefix + UserDataLocalStorageName);
};
export const multiUserLogin = (userId: string) => {
  let sessionToken;
  const userOptions = getOhsMultiUserLoginOptionList();
  const option = _.find(userOptions, { user: { _id: userId } });
  if (option && option.sessionToken) {
    sessionToken = option.sessionToken;
  }
  removeGlobalFilterAndUserDate();

  return OhsApiRequest(
    RequestType.Admin,
    ModuleType.User,
    OhsApiRequestName.MultiUser,
    {
      user: {
        _id: userId,
      },
    },
    { sessionToken: { [OhsSessionToken]: sessionToken } }
  );
};

export const OhsLogout = (isSso: boolean, isMultiUser: boolean) => {
  InactiveSession(isMultiUser).then(() => {
    removeGlobalFilterAndUserDate();
    OhsUserDataServices.resetUserData();
    const mixpanel = getMixpanelTracker();
    mixpanel?.track('Sign out');
    mixpanel?.reset();
    if (isSso === true) {
      window.open('/unlogin.html#logout', '_self');
    } else {
      window.open('/login', '_self');
    }
  });
};
