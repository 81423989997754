import { ModuleType } from 'global-services/constants/OhsObject';

import { getConfigValue, setPermissions } from '../OhsConfigServices';
import OhsCorrespondencePermissions from './OhsCorrespondencePermissions';
import { OhsModuleCategory } from '../OhsModuleCategory';

class OhsCorrespondenceConfig {
  TITLE: string;

  CATEGORY_LIST: OhsModuleCategory[];

  PERMISSIONS: OhsCorrespondencePermissions;

  TYPE = ModuleType.Correspondence;

  constructor(config: any) {
    this.TITLE = getConfigValue(config, 'TITLE');
    this.CATEGORY_LIST = getConfigValue(config, 'CATEGORY_LIST');
    this.PERMISSIONS = new OhsCorrespondencePermissions(false);
    setPermissions(this.PERMISSIONS, getConfigValue(config, 'PERMISSIONS'));
  }
}

export default OhsCorrespondenceConfig;
