import React from 'react';

import { Box, Grid } from '@mui/material';
import { FieldValues } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';
import _ from 'lodash';

import { OhsFormSettings } from 'global-components/form/services/OhsFormModels';
import { useAppSelector } from 'app/hooks';
import OhsSelectField from 'global-components/form/fields/OhsSelectField';
import { getOhsUser } from 'user/userSlice';
import { getOhsInspectionList } from 'inspection/OhsInspectionSlice';
import { useQuery } from 'global-components/tab/OhsTab';
import { OhsTabKeyTypes } from 'global-components/tab/OhsTabModels';
import OhsMultipleSelectField from 'global-components/form/fields/OhsMultipleSelectField';
import OhsDateRangePicker from 'global-components/form/fields/OhsDateRangePicker';

import OhsCheckboxFilter from '../filterfields/OhsCheckboxFilter';
import { getGlobalFilter } from '../OhsTopFilterSlice';
import { OhsFilterList } from '../OhsFilterModels';
import OhsWorkplaceFilter from '../filterfields/OhsWorkplaceFilter';
import OhsTaskDueDateFilter from '../filterfields/OhsTaskDueDateFilter';

const useStyles = makeStyles()(() => ({
  root: {
    flexGrow: 1,
  },
  fieldWrap: {
    margin: '10px 0 10px 0',
  },
  filterContent: {
    width: '100%',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
interface Props {
  ohsFormSettings: OhsFormSettings<FieldValues>;
  isOrg: boolean;
}

const returnStatusLabel = (color: 'blue' | 'orange' | 'yellow' | 'green', label: string) => (
  <Box display="flex" alignItems="center" gap={1}>
    <span className={`ui empty circular label ${color}`} /> <span>{label}</span>
  </Box>
);

function OhsInspectionFilters(props: Props) {
  const { classes } = useStyles();

  const filter = useAppSelector(getGlobalFilter) as OhsFilterList;
  const user = useAppSelector(getOhsUser);
  const inspection = useAppSelector(getOhsInspectionList);
  const userTierNum = user?.tierNum ?? 0;
  const getModuleCategoryLists = user.configs.inspection?.CATEGORY_LIST ?? [];

  const inspectionTypeOptions =
    getModuleCategoryLists.map((item: any) => ({ name: item.category, value: item.category })) ??
    [];
  const query = useQuery();
  const tabKey = query.get('tab') || OhsTabKeyTypes.Scheduled;

  const [subCategoryLists, setSubCategoryLists] = React.useState<string[]>([]);

  React.useEffect(() => {
    const slist = getModuleCategoryLists.map((a: any) => a.subcategory);
    const getSubCategories = _.sortBy(_.uniqBy([...new Set(slist.flat())], (item) => item));
    setSubCategoryLists(getSubCategories);
  }, []);

  const viewPreset = inspection.currentViewPreset;

  const returnTemplateFilters = () => (
    <>
      <Grid item md={12} xs={12} className={classes.fieldWrap}>
        <OhsSelectField
          title="Sort By"
          id={`inspectionModule.${viewPreset}.sort`}
          required
          options={filter.inspectionModule.tableSortKeyLists?.sortColAscDescOptions}
          ohsFormSettings={props.ohsFormSettings}
        />
      </Grid>
      <Grid item md={12} xs={12} className={classes.fieldWrap}>
        <OhsMultipleSelectField
          title="Categories"
          id={`inspectionModule.${viewPreset}.categories`}
          required={false}
          options={inspectionTypeOptions}
          ohsFormSettings={props.ohsFormSettings}
        />
      </Grid>
      <Grid item md={12} xs={12} className={classes.fieldWrap}>
        <OhsMultipleSelectField
          title="Subcategories"
          id={`inspectionModule.${viewPreset}.subcategories`}
          required={false}
          options={subCategoryLists.map((category: any) => ({
            name: category,
            value: category,
          }))}
          ohsFormSettings={props.ohsFormSettings}
        />
      </Grid>
      {userTierNum === 3 && (
        <Grid item md={12} xs={12} className={classes.fieldWrap}>
          <OhsWorkplaceFilter
            id={`inspectionModule.${viewPreset}.workplaces`}
            ohsFormSettings={props.ohsFormSettings}
          />
        </Grid>
      )}
      {userTierNum === 3 && (
        <Grid item md={12} xs={12} className={classes.fieldWrap}>
          <OhsCheckboxFilter
            fieldid={`inspectionModule.${viewPreset}.allocatedToOrg`}
            label="Allocated to Organisation"
            ohsFormSettings={props.ohsFormSettings}
          />
        </Grid>
      )}

      {userTierNum === 3 && (
        // || userTierNum === 4
        <Grid item md={12} xs={12} className={classes.fieldWrap}>
          <OhsCheckboxFilter
            fieldid={`inspectionModule.${viewPreset}.allocatedToWorkplace`}
            label="Allocated to Workplace"
            ohsFormSettings={props.ohsFormSettings}
          />
        </Grid>
      )}
      {userTierNum === 3 && (
        <Grid item md={12} xs={12} className={classes.fieldWrap}>
          <OhsCheckboxFilter
            fieldid={`inspectionModule.${viewPreset}.notAllocated`}
            label="Not Allocated"
            ohsFormSettings={props.ohsFormSettings}
          />
        </Grid>
      )}
      {/* {userTierNum === 4 && (
        <Grid item md={12} xs={12} className={classes.fieldWrap}>
          <OhsCheckboxFilter
            fieldid={`inspectionModule.${viewPreset}.workplaceOwned`}
            label="Workplace Owned"
            ohsFormSettings={props.ohsFormSettings}
          />
        </Grid>
      )} */}
    </>
  );
  const returnScheduledFilters = () => (
    <>
      <Grid item md={12} xs={12} className={classes.fieldWrap}>
        <OhsSelectField
          title="Sort By"
          id={`inspectionModule.${viewPreset}.sort`}
          required
          options={filter.inspectionModule.tableSortKeyLists?.sortScheduledColAscDescOptions}
          ohsFormSettings={props.ohsFormSettings}
        />
      </Grid>
      <Grid item md={12} xs={12} className={classes.fieldWrap}>
        <OhsMultipleSelectField
          title="Categories"
          id={`inspectionModule.${viewPreset}.categories`}
          required={false}
          options={inspectionTypeOptions}
          ohsFormSettings={props.ohsFormSettings}
        />
      </Grid>
      <Grid item md={12} xs={12} className={classes.fieldWrap}>
        <OhsMultipleSelectField
          title="Subcategories"
          id={`inspectionModule.${viewPreset}.subcategories`}
          required={false}
          options={subCategoryLists.map((category: any) => ({
            name: category,
            value: category,
          }))}
          ohsFormSettings={props.ohsFormSettings}
        />
      </Grid>
      {userTierNum === 3 && (
        <Grid item md={12} xs={12} className={classes.fieldWrap}>
          <OhsWorkplaceFilter
            id={`inspectionModule.${viewPreset}.workplaces`}
            ohsFormSettings={props.ohsFormSettings}
          />
        </Grid>
      )}
      <Grid item md={12} xs={12} className={classes.fieldWrap}>
        <OhsTaskDueDateFilter
          fieldId="inspectionModule.dateDue"
          label="Task Due Date"
          ohsFormSettings={props.ohsFormSettings}
        />
      </Grid>
      {userTierNum === 3 && (
        <Grid item md={12} xs={12} className={classes.fieldWrap}>
          <OhsCheckboxFilter
            fieldid={`inspectionModule.${viewPreset}.allocatedToOrg`}
            label={returnStatusLabel('blue', 'Allocated to Organisation')}
            ohsFormSettings={props.ohsFormSettings}
          />
        </Grid>
      )}
      {(userTierNum === 3 || userTierNum === 4) && (
        <Grid item md={12} xs={12} className={classes.fieldWrap}>
          <OhsCheckboxFilter
            fieldid={`inspectionModule.${viewPreset}.allocatedToWorkplace`}
            label={returnStatusLabel('yellow', 'Allocated to Workplace')}
            ohsFormSettings={props.ohsFormSettings}
          />
        </Grid>
      )}
      {(userTierNum === 3 || userTierNum === 4) && (
        <Grid item md={12} xs={12} className={classes.fieldWrap}>
          <OhsCheckboxFilter
            fieldid={`inspectionModule.${viewPreset}.workplaceOwned`}
            label={returnStatusLabel('green', 'Workplace Owned')}
            ohsFormSettings={props.ohsFormSettings}
          />
        </Grid>
      )}
      {userTierNum === 3 && (
        <Grid item md={12} xs={12} className={classes.fieldWrap}>
          <OhsCheckboxFilter
            fieldid={`inspectionModule.${viewPreset}.inProgress`}
            label={returnStatusLabel('orange', 'In Progress')}
            ohsFormSettings={props.ohsFormSettings}
          />
        </Grid>
      )}
    </>
  );

  const returnHistoryFilters = () => (
    <>
      <Grid item md={12} xs={12} className={classes.fieldWrap}>
        <OhsSelectField
          title="Sort By"
          id={`inspectionModule.${viewPreset}.sort`}
          required
          options={filter.inspectionModule.tableSortKeyLists?.sortHistoryOptions}
          ohsFormSettings={props.ohsFormSettings}
        />
      </Grid>
      {userTierNum === 3 && props.isOrg && (
        <Grid item md={12} xs={12} className={classes.fieldWrap}>
          <OhsWorkplaceFilter
            id={`inspectionModule.${viewPreset}.workplaces`}
            ohsFormSettings={props.ohsFormSettings}
          />
        </Grid>
      )}
      <Grid item md={12} xs={12} className={classes.fieldWrap}>
        <Box mb={1} style={{ fontWeight: 'bolder' }}>
          Date Completed Range
        </Box>
        <OhsDateRangePicker
          ohsFormSettings={props.ohsFormSettings}
          id="inspectionModule.dateCompleted"
          startId="start"
          endId="end"
        />
      </Grid>
    </>
  );

  return (
    <Box className={classes.filterContent}>
      {tabKey === OhsTabKeyTypes.Template && returnTemplateFilters()}
      {tabKey === OhsTabKeyTypes.Scheduled && returnScheduledFilters()}
      {tabKey === OhsTabKeyTypes.History && returnHistoryFilters()}
    </Box>
  );
}

export default OhsInspectionFilters;
