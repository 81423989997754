import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import {
  ApiResListsType,
  ApiResponseType,
  OhsApiRequestName,
} from 'global-services/api/OhsApiModels';
import getTaskLists, { getBookmarkListByTask } from 'task/OhsTaskServices';
import { FetchTaskListTypes, OhsTaskListsFilterPayload, TaskListsRecord } from 'task/OhsTaskTypes';
import globalModuleSearch from 'search/OhsSearchServices';
import { OhsTabKeyTypes } from 'global-components/tab/OhsTabModels';
import { ModuleType } from 'global-services/constants/OhsObject';
import handleRegisterAsyncData, {
  handleRegisterCountAsyncData,
} from 'global-components/register/OhsModuleRegisterUtils';

const defaultTaskLists = {
  items: [],
  pagination: {
    page: 0,
    totalPages: 0,
    next: '',
  },
};

export interface TaskListsRecordState {
  isLoading: boolean;
  taskLists: ApiResListsType<TaskListsRecord[]>;
  flaggedTaskLists: ApiResListsType<TaskListsRecord[]> | null;
  flaggedTaskListsByID: ApiResponseType<any> | null;
  currentPage: number;
  currentTab: OhsTabKeyTypes | null;
}

const initialState: TaskListsRecordState = {
  isLoading: true,
  taskLists: defaultTaskLists,
  flaggedTaskLists: null,
  flaggedTaskListsByID: null,
  currentPage: 1,
  currentTab: OhsTabKeyTypes.Active,
};

export const defaultTaskList = {
  items: [],
  pagination: {
    page: 1,
    totalPages: 1,
    next: '',
  },
};

const globalTaskSearch = async (
  state: RootState,
  count: boolean,
  page: number,
  apiRequestName?: OhsApiRequestName
): Promise<ApiResponseType<ApiResListsType<TaskListsRecord[]>> | null> => {
  const {
    globalSearch: { searchInfo },
  } = state;
  const updateModules = [...searchInfo.filter.modules];
  const userTier = state.user.user?.tierNum ?? 0;

  if (userTier !== 5) {
    updateModules.push(ModuleType.Task);
  }
  const requiredSearchFilters =
    apiRequestName === OhsApiRequestName.WorkerTaskAssignedList
      ? {
          page: searchInfo.filter?.page,
          count: searchInfo.filter?.count,
          modules: updateModules,
        }
      : { ...searchInfo.filter, modules: updateModules, count, page };

  const searchFilterInfo = {
    ...searchInfo,
    filter: { ...requiredSearchFilters },
  };

  const globalSearchRes: any = await globalModuleSearch(searchFilterInfo, apiRequestName);
  return globalSearchRes;
};

const taskListsFilterOptions = (state: RootState): Partial<OhsTaskListsFilterPayload> => {
  const { globalfilter, tasks, user } = state;
  const { filterInfo } = globalfilter;
  const userTier = (user.user && user.user.tierNum) ?? 100;
  const page = tasks.currentPage ?? 1;
  return {
    ...(userTier <= 3 && { skipOrgRecords: filterInfo.taskModule.skipOrgRecords }),
    modules: filterInfo.taskModule.modules ?? [],
    workplaces: filterInfo.taskModule.workplaces ?? [],
    next: page > 10 ? tasks.taskLists?.pagination.next ?? '' : undefined,
    // remove page if it is greater than 10
    ...(page <= 10 && { page }),
    dateDue: filterInfo.taskModule.dateDue,
  };
};

export const fetchTaskListsAsync = createAsyncThunk<
  ApiResponseType<ApiResListsType<TaskListsRecord[]>> | null,
  FetchTaskListTypes,
  { state: RootState }
>('tasks/fetchTaskList', async (taskInfo: FetchTaskListTypes, thunkAPI) => {
  const state = thunkAPI.getState();
  const hasGlobalSearch = state.globalSearch.searchInfo.searchKey !== '';
  const userTier = state.user.user?.tierNum ?? 0;
  const { currentPage } = state.tasks;
  const filter = state.globalfilter.filterInfo.taskModule;
  const updateTaskFilter: OhsTaskListsFilterPayload = {
    ...taskListsFilterOptions(state),
    // remove page if it is greater than 10
    ...(currentPage <= 10 && { page: currentPage }),
    sort: { ...JSON.parse(String(filter.sort)) },
    count: false,
  };
  try {
    const requiredApirequest =
      userTier === 5 ? OhsApiRequestName.WorkerTaskAssignedList : OhsApiRequestName.List;

    const response = hasGlobalSearch
      ? await globalTaskSearch(state, false, currentPage, requiredApirequest)
      : await getTaskLists(taskInfo, updateTaskFilter);

    return response;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const fetchTaskListsCountAsync = createAsyncThunk<
  ApiResponseType<ApiResListsType<TaskListsRecord[]>> | null,
  FetchTaskListTypes,
  { state: RootState }
>('tasks/fetchTaskListCount', async (taskInfo: FetchTaskListTypes, thunkAPI) => {
  const state = thunkAPI.getState();
  const hasGlobalSearch = state.globalSearch.searchInfo.searchKey !== '';
  const userTier = state.user.user?.tierNum ?? 0;
  const filter = state.globalfilter.filterInfo.taskModule;
  const taskFiltersWithCount: OhsTaskListsFilterPayload = {
    ...taskListsFilterOptions(state),
    count: true,
    page: 1,
    sort: { ...JSON.parse(String(filter.sort)) },
  };
  try {
    const requiredApirequest =
      userTier === 5 ? OhsApiRequestName.WorkerTaskAssignedList : OhsApiRequestName.List;

    const response = hasGlobalSearch
      ? await globalTaskSearch(state, true, 1, requiredApirequest)
      : await getTaskLists(taskInfo, taskFiltersWithCount);

    return response;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const fetchFlaggedTaskListsAsync = createAsyncThunk<
  ApiResponseType<ApiResListsType<TaskListsRecord[]>> | null,
  FetchTaskListTypes,
  { state: RootState }
>('tasks/fetchFlaggedTaskList', async (taskInfo: FetchTaskListTypes, thunkAPI) => {
  const state = thunkAPI.getState();
  const filter = state.globalfilter.filterInfo.taskModule;
  const updateTaskFilter: OhsTaskListsFilterPayload = {
    ...taskListsFilterOptions(state),
    page: 1,
    sort: { ...JSON.parse(String(filter.sort)) },
    count: false,
  };

  try {
    const response = await getTaskLists(taskInfo, updateTaskFilter);
    return response;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const fetchFlaggedTaskListByIdAsync = createAsyncThunk<
  ApiResponseType<any> | null,
  string[],
  { state: RootState }
>('tasks/fetchFlaggedTaskListByIDAsync', async (_ids: any[], thunkAPI) => {
  try {
    const response = await getBookmarkListByTask(_ids);
    return response;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response);
  }
});

export const taskSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    setTaskCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setTaskCurrentTab: (state, action: PayloadAction<OhsTabKeyTypes>) => {
      state.currentTab = action.payload;
    },
    setTaskIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    resetTasksLists: (state) => {
      state.taskLists = defaultTaskLists;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTaskListsAsync.pending, (state) => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        state.isLoading = true;
        state.taskLists = defaultTaskLists;
      })
      .addCase(fetchTaskListsAsync.fulfilled, (state, action) => {
        const taskListRes = action.payload;
        if (taskListRes && taskListRes.success) {
          const taskLists = handleRegisterAsyncData(taskListRes.result, 'taskListTotalPages');
          state.isLoading = false;
          state.taskLists = taskLists;
        }
      })
      .addCase(fetchTaskListsCountAsync.fulfilled, (state, action) => {
        const taskListCountRes = action.payload;
        const taskCountResult = action.payload?.result ?? defaultTaskList;
        if (taskListCountRes && taskListCountRes.success) {
          const countTaskLists = handleRegisterCountAsyncData(
            taskCountResult,
            state.taskLists,
            'taskListTotalPages'
          );
          state.taskLists = countTaskLists;
        }
      })
      .addCase(fetchFlaggedTaskListsAsync.fulfilled, (state, action) => {
        const flaggedTaskCountResult = action.payload?.result ?? defaultTaskList;

        if (action.payload && action.payload.success) {
          state.flaggedTaskLists = flaggedTaskCountResult;
        }
      })
      .addCase(fetchFlaggedTaskListByIdAsync.fulfilled, (state, action) => {
        if (action.payload && action.payload.success) {
          state.flaggedTaskListsByID = action.payload;
        }
      });
  },
});

const taskState = (state: RootState) => state.tasks;
// Memoized Selectors
export const getOhsTaskModuleState = createSelector([taskState], (tasks) => tasks);
export const { setTaskCurrentPage, resetTasksLists, setTaskCurrentTab, setTaskIsLoading } =
  taskSlice.actions;
export const taskModuleReducer = taskSlice.reducer;
