import { OhsUser } from 'user/OhsUser';
import { ModuleUrl, ModuleRoute } from 'global-services/constants/OhsRoutes';
import { ModuleConfig } from 'global-services/constants/OhsObject';
import { withCreatePermission, withSignOffViewPermission } from 'global-services/OhsPermission';

const commonPermission = (user: OhsUser) => user?.configs?.chemical?.PERMISSIONS.view || false;

const createRoute = (path: string, permission: (user: OhsUser) => boolean) => ({
  path,
  url: `/${ModuleUrl.Chemical}/${path}`,
  permission,
});

const OhsChemicalRouteList = {
  [ModuleRoute.Home]: createRoute('home', commonPermission),
  [ModuleRoute.Add]: createRoute('add', (user) =>
    withCreatePermission(user, ModuleConfig.Chemical)
  ),
  [ModuleRoute.Details]: createRoute(':chemicalId/view', commonPermission),
  [ModuleRoute.Edit]: createRoute(':chemicalId/edit', commonPermission),
  [ModuleRoute.SignOff]: createRoute(':chemicalId/task/:taskId/signoff', (user) =>
    withSignOffViewPermission(user, ModuleConfig.Chemical)
  ),
  [ModuleRoute.Evidence]: createRoute(':chemicalId/task/:taskId/completed', commonPermission),
  [ModuleRoute.Allocate]: createRoute(':chemicalId/allocate', commonPermission),
};

export default OhsChemicalRouteList;
