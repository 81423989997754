import { OhsUser } from 'user/OhsUser';
import { ModuleUrl, ModuleRoute } from 'global-services/constants/OhsRoutes';
import { withCreatePermission, withSignOffViewPermission } from 'global-services/OhsPermission';
import { ModuleConfig } from 'global-services/constants/OhsObject';

const commonPermission = (user: OhsUser) => user?.configs?.asset?.PERMISSIONS.view || false;
const createRoute = (path: string, permission: (user: OhsUser) => boolean) => ({
  path,
  url: `/${ModuleUrl.Asset}/${path}`,
  permission,
});

const OhsAssetRouteList = {
  [ModuleRoute.Home]: createRoute('home', commonPermission),
  [ModuleRoute.Add]: createRoute('add', (user) => withCreatePermission(user, ModuleConfig.Asset)),
  [ModuleRoute.Details]: createRoute(':assetId/view', commonPermission),
  [ModuleRoute.Edit]: createRoute(':assetId/edit', commonPermission),
  [ModuleRoute.SignOff]: createRoute(':assetId/task/:taskId/signoff', (user) =>
    withSignOffViewPermission(user, ModuleConfig.Asset)
  ),
  [ModuleRoute.Evidence]: createRoute(':assetId/task/:taskId/completed', commonPermission),
};

export default OhsAssetRouteList;
