import { AxiosResponse } from 'axios';
import _ from 'lodash';

import { ModuleType } from 'global-services/constants/OhsObject';
import { OhsApiRequestName } from 'global-services/api/OhsApiModels';

import getMixpanelTracker from './OhsMixpanel';

const capitalizeFirstLetter = (words: string) => {
  if (words && words.length) return words.charAt(0).toUpperCase() + words.slice(1);
  return '';
};

const byteLength = (str: string): number => {
  if (!str) return 0;

  // returns the byte length of an utf8 string
  let s = str.length;
  for (let i = str.length - 1; i >= 0; i -= 1) {
    const code = str.charCodeAt(i);
    if (code > 0x7f && code <= 0x7ff) s += 1;
    else if (code > 0x7ff && code <= 0xffff) s += 2;
    if (code >= 0xdc00 && code <= 0xdfff) i -= 1; // trail surrogate
  }
  return s;
};
const divideString = (propertyName: string, jsonString: string): any => {
  const result = {} as any;
  if (jsonString) {
    const StringSize = 300;
    let counter = 0;
    do {
      let name = propertyName;
      if (counter > 0) {
        name = propertyName + counter;
      }
      const subError = jsonString.substring(
        counter * StringSize,
        counter * StringSize + StringSize
      );
      result[name] = subError;

      counter += 1;
    } while (jsonString.length > counter * StringSize);
  }

  return result;
};
const getEventProperties = (wrapper: any) => {
  let payload = null;
  //   let properties: any = {};

  if (wrapper.config && wrapper.config.data) {
    if (_.isObject(wrapper.config.data)) {
      if (_.isFunction(wrapper.config.data.values)) {
        // At this point, `wrapper.config.data` is an
        // instance of FormData; since operator `instanceof`
        // is not available on ES5, workaround is to
        // check if `values` property is a function
        // which will return an iterator

        // Since we only register (1) key on formData
        // across all request calls, `value` will always
        // be the `json` property attribute

        payload = wrapper.config.data.values().next().value;
      } else {
        payload = wrapper.config.data;
      }
    } else {
      payload = wrapper.config.data;
    }
  }

  const nowTimestamp = new Date().getTime();
  const errorFlag = wrapper.response && wrapper.response.success === false;
  const properties: any = {
    EndpointURL: wrapper.config.url,
    ResponseTime:
      wrapper.config.requestTimestamp != null ? nowTimestamp - wrapper.config.requestTimestamp : '',
    ResponsePayloadSize: parseInt(wrapper.headers['content-length'], 10),
    RequestPayloadSize: byteLength(payload),
    HTTPMethod: wrapper.config.method,
    SuccessfulResponse: wrapper.response != null && wrapper.response.success ? 'Yes' : 'No',
  };
  const ErrorInfo = errorFlag
    ? divideString('ErrorInfo', JSON.stringify(wrapper.response.error) ?? '')
    : {};
  const ErrorPayload = errorFlag ? divideString('ErrorPayload', wrapper.config?.data ?? '') : {};

  return { ...properties, ...ErrorPayload, ...ErrorInfo };
};
const getEventName = (config: any): string => {
  let name = '';
  const URL = config.url;
  if (config && config.data) {
    try {
      const requestPayload = JSON.parse(config.data) as {
        method: {
          type: ModuleType;
          name: OhsApiRequestName;
        };
        payload: any;
      };
      if (
        requestPayload &&
        requestPayload.method &&
        requestPayload.method.name &&
        requestPayload.method.type &&
        URL
      ) {
        const typeArray = requestPayload.method.type.split('.');
        let typeName = '';
        typeArray.forEach((item) => {
          if (item !== 'core' && item !== 'module') {
            if (typeName) {
              typeName = `${typeName} ${capitalizeFirstLetter(item)}`;
            } else {
              typeName = `${capitalizeFirstLetter(item)}`;
            }
          }
        });
        const requestNameArray = requestPayload.method.name.split('.');
        let requestName = '';
        requestNameArray.forEach((item) => {
          if (requestName) {
            requestName = `${requestName} ${capitalizeFirstLetter(item)}`;
          } else {
            requestName = `${capitalizeFirstLetter(item)}`;
          }
        });
        if (typeName && requestName) {
          name = `${capitalizeFirstLetter(URL.substring(1))} ${typeName} ${requestName}`;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  return name;
};
const track = (config: any, response: any, headers: any) => {
  const mixpanel = getMixpanelTracker();
  if (mixpanel == null) return;

  mixpanel.init(
    (window as any).MIXPANEL_ID ? (window as any).MIXPANEL_ID : '03776c63a39a3c98908d60ac2e2859db',
    { track_pageview: 'url-with-path-and-query-string' },
    'URL Change'
  );

  const properties = getEventProperties({
    config,

    response,
    headers,
  });
  const eventName = getEventName(config);

  if (eventName) {
    try {
      mixpanel.track(eventName, properties);
    } catch (error) {
      console.error(error);
    }
  }
};
export const responseMixpanelInterceptor = (response: AxiosResponse<any>): AxiosResponse<any> => {
  try {
    track(response.config, response.data, response.headers);
  } catch (e) {
    console.error(e);
  }
  return response;
};

export const responseRejectedMixpanelInterceptor = (error: any): Promise<any> => {
  try {
    track(error.config, { success: false, error: 'responseError' }, error.headers);
  } catch (e) {
    console.error(e);
  }
  return Promise.reject(error);
};
