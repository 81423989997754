/* eslint-disable max-len */
import React from 'react';

import { Button, ModalDescription } from 'semantic-ui-react';

import OhsModal from 'global-components/modal/OhsModal';
import { OhsUser } from 'user/OhsUser';
import OhsMessageBox from 'global-components/message-box/OhsMessageBox';
import { getLocalStorage, setLocalStorage } from 'global-services/OhsDataParse';

import { OhsLogout } from './OhsLoginServices';

interface Props {
  user: OhsUser;
  open: boolean;
  setLogoutModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function OhsLogoutModal(props: Props) {
  const { user } = props;
  const handleClose = () => {
    props.setLogoutModalOpen(false);
  };

  const handleTrustLogout = async () => {
    OhsLogout(user?.isSso === true, user.isMultiUser);
    props.setLogoutModalOpen(false);
  };

  // Filters out the session associated with the current user's email.
  // Saves the updated array of active sessions back to local storage.
  const handleClearUserLogout = async () => {
    const getUserActiveSessions = getLocalStorage('userActiveSessions') ?? [];
    const deleteUserActiveSession = getUserActiveSessions.filter(
      (item: any) => item.email !== user.email
    );
    setLocalStorage('userActiveSessions', [...deleteUserActiveSession]);
    OhsLogout(user?.isSso === true, user.isMultiUser);
    props.setLogoutModalOpen(false);
  };
  const handleCancel = async () => {
    props.setLogoutModalOpen(false);
  };

  return (
    <OhsModal
      open={props.open}
      title="Log out"
      onClose={handleClose}
      ContentNode={
        <ModalDescription>
          <OhsMessageBox
            title="Log out of safetychampion.online?"
            description="Trusting this browser will save your authentication and system settings, you will still be required to enter your user name and password to log back in."
          />
        </ModalDescription>
      }
      actionNode={
        <div style={{ width: '100%' }}>
          <Button floated="right" onClick={() => handleTrustLogout()} color="green">
            Trust and Log Out
          </Button>
          <Button onClick={() => handleClearUserLogout()}>Forget and Log Out</Button>
          <Button onClick={() => handleCancel()}>Cancel</Button>
        </div>
      }
    />
  );
}

export default OhsLogoutModal;
