import mixpanel, { Mixpanel } from 'mixpanel-browser';

import OhsUserLocalServices from 'user/OhsUserLocalServices';

import OhsMixpanelRegister from './OhsMixpanelRegister';
import OhsMixpanelPeople from './OhsMixpanelPeople';

const getMixpanelTracker = (): Mixpanel | null => {
  mixpanel.init(
    (window as any).MIXPANEL_ID ? (window as any).MIXPANEL_ID : '03776c63a39a3c98908d60ac2e2859db'
  );

  try {
    const user = OhsUserLocalServices.getLocalOhsUser();
    const ohsMixpanelRegister = new OhsMixpanelRegister(user);
    const people = new OhsMixpanelPeople(user);
    mixpanel.register(ohsMixpanelRegister);

    mixpanel.people.set(people);

    mixpanel.identify(user?.email.toLowerCase());
    mixpanel.track('Reload Application Page');
    return mixpanel;
  } catch (e) {
    return null;
  }
};

export default getMixpanelTracker;
