import React from 'react';

import { generatePath, useNavigate } from 'react-router-dom';
import { AxiosResponse, InternalAxiosRequestConfig } from 'axios';

import axios from 'global-services/api/OhsApi';
import {
  OhsApiErrorCodes,
  OhsApiRequestName,
  checkContainsErrorCode,
} from 'global-services/api/OhsApiModels';
import OhsMfaVerifyModal from 'user/user-settings/mfa/verifyflows/OhsMfaVerifyModal';
import OhsMfaEnrollModal from 'user/user-settings/mfa/enrollflows/OhsMfaEnrollModal';
import { getHomeUrl, multiUserLogin } from 'login/OhsLoginServices';
import OhsTaskRouteList from 'task/OhsTaskRouteList';
import { morphInto } from 'user/morph/MorphServices';
import checkCurrentUrlAfterMorph from 'user/morph/UrlCheckServices';
import { getOhsUser } from 'user/userSlice';
import { fetchUserMfaList, userMfaCancel } from 'user/user-settings/mfa/enrollflows/OhsMfaServices';
import { OhsMfaActiveMethodListTypes } from 'user/user-settings/mfa/OhsMfaTypes';

import { useAppSelector } from './hooks';

type MFaRequestInfo = {
  requestName: OhsApiRequestName.MorphInto | OhsApiRequestName.MultiUser;
  id: string;
};
function OhsMfaRequire() {
  const navigate = useNavigate();
  const user = useAppSelector(getOhsUser);
  const [verifyMfalModalOpen, setVerifyMfalModalOpen] = React.useState(false);
  const [enrollModalOpen, setEnrollModalOpen] = React.useState(false);
  const [requestInfo, setRequestInfo] = React.useState<MFaRequestInfo>();
  const mfaStatus = user?.configs.admin2?.MFA;
  const modulePath = window.location.pathname.split('/')[2];

  const handleOtpVerifiedSuccess = async () => {
    try {
      let response: any;
      if (requestInfo && requestInfo.requestName === OhsApiRequestName.MorphInto) {
        response = await morphInto(requestInfo.id);
        if (response.success) checkCurrentUrlAfterMorph();
      } else if (requestInfo && requestInfo?.requestName === OhsApiRequestName.MultiUser) {
        response = await multiUserLogin(requestInfo.id);
        if (response) {
          window.open(getHomeUrl(response.tier), '_self');
        }
      } else {
        navigate(0);
      }
    } catch (error) {
      console.error('Error in handleOtpVerifiedSuccess:', error);
    }
  };

  const handleMfaEnrollClose = (currentStep: number) => {
    // Do not redirect to dashboard before step 4 (last step)
    if (currentStep === 4) {
      const url = generatePath(OhsTaskRouteList.active.url);
      navigate(url);
      navigate(0);
    }
  };

  const getUserMfaList = async (): Promise<OhsMfaActiveMethodListTypes> => {
    const userMfaList: OhsMfaActiveMethodListTypes = await fetchUserMfaList();
    return userMfaList;
  };

  const interceptors = React.useMemo(
    () => ({
      request: (config: InternalAxiosRequestConfig) => {
        switch (config.data.method.name) {
          case OhsApiRequestName.MorphInto: // Morph
            setRequestInfo({
              requestName: OhsApiRequestName.MorphInto,
              id: config.data.payload.target._id,
            });
            break;
          case OhsApiRequestName.MultiUser: // Switch Tier / Workplace
            setRequestInfo({
              requestName: OhsApiRequestName.MultiUser,
              id: config.data.payload.user._id,
            });
            break;
          default:
            break;
        }
        return config;
      },
      response: async (response: AxiosResponse) => {
        const errorCode = response?.data?.error;

        // check if MFA is required
        const mfaIsRequired = checkContainsErrorCode(errorCode, OhsApiErrorCodes.MfaRequire);
        if (mfaStatus && mfaIsRequired) {
          if (mfaStatus === 'required') {
            // Enforce MFA
            const mfaActiveList: OhsMfaActiveMethodListTypes = await getUserMfaList();
            const hasNoActiveMfaMethods = mfaActiveList?.authenticators?.length === 0;
            // check if MFA is required and NO active MFA method
            if (hasNoActiveMfaMethods) {
              setEnrollModalOpen(true);
            } else {
              setVerifyMfalModalOpen(true);
            }
          } else {
            // RE-OPEN MFA verify modal if "The provided token has an ongoing active session."
            const hasActiveSession = checkContainsErrorCode(
              errorCode,
              OhsApiErrorCodes.UserInvalidSession
            );
            if (mfaIsRequired || hasActiveSession) setVerifyMfalModalOpen(true);
          }
        }

        return response;
      },
    }),
    [mfaStatus]
  );

  const loadAxiosInterceptors = () => {
    const requestId = axios.interceptors.request.use(interceptors.request);
    const responseId = axios.interceptors.response.use(interceptors.response);
    return () => {
      // remove all intercepts when done
      axios.interceptors.request.eject(requestId);
      axios.interceptors.response.eject(responseId);
    };
  };

  React.useEffect(() => {
    if (modulePath !== 'user-settings') {
      loadAxiosInterceptors();
    }
  }, [modulePath, interceptors]);

  return (
    <div>
      <OhsMfaVerifyModal
        title="Multi-factor authentication (MFA)"
        open={verifyMfalModalOpen}
        setModalOpen={setVerifyMfalModalOpen}
        onSuccess={handleOtpVerifiedSuccess}
        onClose={async () => userMfaCancel()}
      />
      <OhsMfaEnrollModal
        title="Multi-factor authentication (MFA)"
        open={enrollModalOpen}
        setModalOpen={setEnrollModalOpen}
        handleOnClose={handleMfaEnrollClose}
      />
    </div>
  );
}

export default OhsMfaRequire;
