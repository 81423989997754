/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import _ from 'lodash';
import { toast } from 'react-toastify';

import {
  CookiesExpiresDays,
  OhsLinkAccessAttachmentsDownloadHeader,
  OhsLinkAccessAttachmentsUploadHeader,
  OhsMultiUserOptionName,
  OhsMultiUserSessionToken,
  OhsSessionToken,
} from 'global-services/constants/OhsStorageNames';
import { getOhsMultiUserLoginOptionList } from 'login/multi/OhsMultiUserLoginOption';
import { getLocalStorage, setLocalStorage } from 'global-services/OhsDataParse';
import { ModuleUrl } from 'global-services/constants/OhsRoutes';
import OhsUserDataServices from 'user/OhsUserDataServices';

import {
  getApiRequestErrorMessage,
  OhsApiErrorCodes,
  OhsApiRequestName,
  OhsApiRequestOptions,
} from './OhsApiModels';

const showSuccess = (msg: any, options?: OhsApiRequestOptions) => {
  if (options?.disableToast === true) return;
  toast.success(msg);
};

const showError = (error: any, options?: OhsApiRequestOptions) => {
  if (options?.disableToast === true || options?.disableErrorToast === true) return;
  toast.error(error);
};

// Add / Update user active sessions to be used for login
const updateSessionToken = (response: AxiosResponse, payload?: any) => {
  const userEmail = _.get(response, 'data.result.item.email') ?? _.get(payload, 'email');
  if (userEmail) {
    const getUserActiveSessions = getLocalStorage('userActiveSessions') ?? [];
    if (!getUserActiveSessions) {
      setLocalStorage('userActiveSessions', []);
    }
    const tokenIndex = getUserActiveSessions.findIndex((item: any) => item.email === userEmail);
    if (tokenIndex >= 0) {
      // Update access token
      if (
        getUserActiveSessions[tokenIndex].multiUser &&
        response.headers[OhsMultiUserSessionToken]
      ) {
        getUserActiveSessions[tokenIndex][OhsMultiUserSessionToken] =
          response.headers[OhsMultiUserSessionToken];
      }
      getUserActiveSessions[tokenIndex].timeStamp = new Date().getTime();
      getUserActiveSessions[tokenIndex][OhsSessionToken] = response.headers[OhsSessionToken];
      setLocalStorage('userActiveSessions', [...getUserActiveSessions]);
    } else {
      getUserActiveSessions.push({
        email: userEmail,
        multiUser: _.has(response, 'data.result.item.multiuser') ?? false,
        timeStamp: new Date().getTime(),
        [OhsMultiUserSessionToken]: response.headers[OhsMultiUserSessionToken],
        [OhsSessionToken]: response.headers[OhsSessionToken],
      });
      setLocalStorage('userActiveSessions', getUserActiveSessions);
    }
  }
};
const updateSession = (response: AxiosResponse, methodName: OhsApiRequestName, payload?: any) => {
  if (response.headers && response.headers[OhsSessionToken]) {
    const newSessionToken = response.headers[OhsSessionToken];
    updateSessionToken(response, payload);

    Cookies.set(OhsSessionToken, newSessionToken, {
      expires: CookiesExpiresDays,
      path: '/',
    });
    if (
      newSessionToken &&
      methodName === OhsApiRequestName.MultiUser &&
      _.has(payload, 'user._id')
    ) {
      const userOptions = getOhsMultiUserLoginOptionList();
      const userId = payload.user._id;
      for (let index = 0; index < userOptions.length; index += 1) {
        const element = userOptions[index];
        if (element.user._id === userId) {
          element.sessionToken = newSessionToken;

          if (_.has(response, 'data.result.item.email')) {
            updateSessionToken(response);
          }
          break;
        }
      }

      setLocalStorage(OhsMultiUserOptionName, userOptions);
    }
  }

  if (response.headers && response.headers[OhsMultiUserSessionToken]) {
    updateSessionToken(response);

    Cookies.set(OhsMultiUserSessionToken, response.headers[OhsMultiUserSessionToken], {
      expires: CookiesExpiresDays,
      path: '/',
    });
  }

  // communication module

  if (
    response.headers &&
    response.headers[OhsLinkAccessAttachmentsDownloadHeader] &&
    _.has(response, 'data.result.item._id')
  ) {
    response.data.result.item.downloadToken =
      response.headers[OhsLinkAccessAttachmentsDownloadHeader];
  }
  if (
    response.headers &&
    response.headers[OhsLinkAccessAttachmentsUploadHeader] &&
    _.has(response, 'data.result.item._id')
  ) {
    response.data.result.item.uploadToken = response.headers[OhsLinkAccessAttachmentsUploadHeader];
  }
};

const handleOhsApiErrors = (
  response: any,
  methodName: OhsApiRequestName,

  options?: OhsApiRequestOptions
) => {
  let isShowErrorToast = true;
  if (
    _.has(response, 'data.error.code') &&
    response.data.error.code &&
    response.data.error.code.length
  ) {
    if (response.data.error.code.includes(OhsApiErrorCodes.InvalidVisitLeave)) {
      isShowErrorToast = false;
    }
    if (response.data.error.code.includes(OhsApiErrorCodes.SessionExpired)) {
      isShowErrorToast = false;
      OhsUserDataServices.resetUserData();
      window.location.href = `/${ModuleUrl.Login}`;
    }
    if (response.data.error.code.includes(OhsApiErrorCodes.UserInvalidSession)) {
      isShowErrorToast = false;
      toast.error('Login failed, Please try again!');

      // Remove user access token from local storage
      const getUserActiveSessions = getLocalStorage('userActiveSessions') ?? [];
      const removeUserAccessToken = getUserActiveSessions.filter(
        (item: any) =>
          item[OhsMultiUserSessionToken] !== Cookies.get(OhsMultiUserSessionToken) ||
          item[OhsSessionToken] !== Cookies.get(OhsSessionToken)
      );
      setLocalStorage('userActiveSessions', removeUserAccessToken);
      OhsUserDataServices.resetUserData();

      if (window.location.pathname !== `/${ModuleUrl.Login}`)
        window.location.href = `/${ModuleUrl.Login}`;
    }
  }
  if (isShowErrorToast === true)
    showError(getApiRequestErrorMessage(methodName, response?.data?.error), options);
};

const Dev1Url = 'https://api.dev1.safetychampion.dev';

const Dev2Url = 'https://api.dev2.safetychampion.dev';

const be3ProdMigration = 'https://api3.safetychampion.online';

const getApiRoot = () => {
  const { API_ROOT } = window as any;

  if (API_ROOT) {
    return API_ROOT;
  }
  return Dev1Url;
  // return Dev2Url;
};

const OhsApiServices = { updateSession, handleOhsApiErrors, showSuccess, showError, getApiRoot };
export default OhsApiServices;
