import { OhsUser } from 'user/OhsUser';
import { ModuleUrl, ModuleRoute } from 'global-services/constants/OhsRoutes';

const commonPermission = (user: OhsUser) => user?.configs?.noticeboard?.PERMISSIONS.view || false;

const createRoute = (path: string, permission: (user: OhsUser) => boolean) => ({
  path,
  url: `/${ModuleUrl.Noticeboard}/${path}`,
  permission,
});

const OhsNoticeBoardRouteList = {
  [ModuleRoute.Home]: createRoute('home', commonPermission),
  [ModuleRoute.ADMIN]: createRoute('admin', commonPermission),
  [ModuleRoute.Details]: createRoute(':noticeboardId/view', commonPermission),
  [ModuleRoute.FormAdd]: createRoute('form/add', commonPermission),
  [ModuleRoute.FormEdit]: createRoute('form/:noticeBoardFormId/add', commonPermission),
};

export default OhsNoticeBoardRouteList;
