import { Button } from '@mui/material';
import { withStyles } from '@mui/styles';
import { green } from '@mui/material/colors';

const OhsGreenButton = withStyles(() => ({
  root: {
    textTransform: 'none',
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: green[600],
    '&:hover': {
      backgroundColor: green[800],
    },
  },
}))(Button);
export default OhsGreenButton;
