import { OhsUser } from 'user/OhsUser';
import { withSignOffViewPermission } from 'global-services/OhsPermission';
import { ModuleUrl, ModuleRoute } from 'global-services/constants/OhsRoutes';
import { ModuleConfig } from 'global-services/constants/OhsObject';

const commonPermission = (user: OhsUser) => user?.configs?.safetyplan?.PERMISSIONS.view || false;
const createRoute = (path: string, permission: (user: OhsUser) => boolean) => ({
  path,
  url: `/${ModuleUrl.SafetyPlan}/${path}`,
  permission,
});

const SafetyPlanRouteList = {
  [ModuleRoute.Home]: createRoute('home', commonPermission),
  [ModuleRoute.Details]: createRoute(':safetyPlanId/details', commonPermission),
  [ModuleRoute.SignOff]: createRoute(':safetyPlanId/task/:taskId/signoff', (user) =>
    withSignOffViewPermission(user, ModuleConfig.SafetyPlan)
  ),
  [ModuleRoute.Evidence]: createRoute(':safetyPlanId/task/:taskId/completed', commonPermission),
  [ModuleRoute.TasksAggregate]: createRoute(':safetyPlanId/aggregate', commonPermission),
};

export default SafetyPlanRouteList;
