import { OhsUser } from 'user/OhsUser';
import { ModuleUrl, ModuleRoute } from 'global-services/constants/OhsRoutes';
import { withCreatePermission } from 'global-services/OhsPermission';
import { ModuleConfig } from 'global-services/constants/OhsObject';

const commonPermission = (user: OhsUser) => user?.configs?.contractor?.PERMISSIONS.view || false;

const createRoute = (path: string, permission: (user: OhsUser) => boolean) => ({
  path,
  url: `/${ModuleUrl.Contractor}/${path}`,
  permission,
});

const OhsContractorRouteList = {
  [ModuleRoute.Home]: createRoute('home', commonPermission),
  [ModuleRoute.Details]: createRoute(':contractorId/view', commonPermission),
  [ModuleRoute.Add]: createRoute('add', (user) =>
    withCreatePermission(user, ModuleConfig.Contractor)
  ),
  [ModuleRoute.Edit]: createRoute(':contractorId/edit', commonPermission),
};

export default OhsContractorRouteList;
