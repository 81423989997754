import { getOhsLocalStorage, SsoFlagName } from 'global-services/OhsDataParse';
import { OhsModule, ModuleType } from 'global-services/constants/OhsObject';
import { FreemiumOrgId } from 'global-services/constants/OhsConstants';
import { OhsTier } from 'global-services/constants/tier/OhsTier';

import OhsConfigurations from './configurations/OhsConfigurations';

export class OhsUser implements OhsModule {
  readonly type: ModuleType = ModuleType.User;

  _id!: string;

  name!: string;

  email!: string;

  tier!: OhsTier;

  orgAdmin: OhsTier | null;

  org: OhsTier | null;

  workplace: OhsTier | null;

  workerGroup: OhsTier | null;

  isMorph: boolean;

  morphType: OhsUserMorphType;

  isSso: boolean;

  isMultiUser: boolean;

  tierNum!: number;

  level: OhsUserPlanType;

  configs: OhsConfigurations;

  mfa?: OhsMFAsettings;

  constructor(userPayload: any) {
    this.orgAdmin = null;
    this.org = null;
    this.workplace = null;
    this.workerGroup = null;
    this.level = 'paid';
    this.configs = new OhsConfigurations(userPayload.configuration);

    if (userPayload && userPayload.user) {
      this._id = userPayload.user._id;
      this.name = userPayload.user.name;
      this.email = userPayload.user.email;
      this.tier = new OhsTier(userPayload.tier);
      this.tierNum = parseInt(this.tier.type.substring(11), 10);
      if (userPayload.user.orgAdmin && userPayload.user.orgAdmin._id) {
        this.orgAdmin = userPayload.user.orgAdmin;
      }
      if (userPayload.user.org && userPayload.user.org._id) {
        this.org = userPayload.user.org;
      }
      if (userPayload.user.workplace && userPayload.user.workplace._id) {
        this.workplace = userPayload.user.workplace;
      }
      if (userPayload.user.workerGroup && userPayload.user.workerGroup._id) {
        this.workerGroup = userPayload.user.workerGroup;
      }
      if (this.orgAdmin && this.orgAdmin._id && this.orgAdmin._id === FreemiumOrgId) {
        this.level = 'free';
      }
    }

    const isMorph = userPayload.user?.morph;
    if (isMorph === true) {
      this.isMorph = true;
    } else {
      this.isMorph = false;
    }
    this.morphType = 'None';
    if (this.isMorph === true) {
      if (this.tierNum === 2) {
        this.morphType = 'Up';
      } else if (this.tierNum === 4) {
        this.morphType = 'Down';
      }
    }
    const isSso = getOhsLocalStorage(SsoFlagName);
    if (isSso === true) {
      this.isSso = true;
    } else {
      this.isSso = false;
    }

    const isMultiUser = userPayload.user?.multiuser;
    if (isMultiUser === true) {
      this.isMultiUser = true;
    } else {
      this.isMultiUser = false;
    }
  }
}

export interface OhsMFAsettings {
  enabled: boolean;
  method: string;
}

export interface OhsMfaMethodSettings {
  app: boolean;
  sms: boolean;
  email: boolean;
}
export { SsoFlagName };

export type OhsUserMorphType = 'None' | 'Up' | 'Down';

export type OhsUserPlanType = 'paid' | 'free';

export type OhsUserLoginType = 'Azure SSO' | 'Username Password';
