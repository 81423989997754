import React from 'react';

import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';
import _ from 'lodash';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Box, IconButton, OutlinedInput } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import InputAdornment from '@mui/material/InputAdornment';

import { OhsLowerTier } from 'global-services/constants/tier/OhsTier';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getOhsUser } from 'user/userSlice';
import { morphInto } from 'user/morph/MorphServices';
import checkCurrentUrlAfterMorph from 'user/morph/UrlCheckServices';

import logo from '../angular-modules/build/img/sc-logo.png';
import { getOhsDashboard, toggleRightSideDrawer } from './OhsDashboardSlice';

const useStyles = makeStyles()((theme: any) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
}));

function OhsRightSideDrawer() {
  const { classes } = useStyles();
  const dashboard = useAppSelector(getOhsDashboard);
  const user = useAppSelector(getOhsUser);
  const [searchKey, setSearchKey] = React.useState('');
  const [lowerTierList, setLowerTierList] = React.useState(user?.configs.admin2?.LOWER_TIER_ACCESS);

  const dispatch = useAppDispatch();
  const cleanKey = () => {
    setSearchKey('');
    setLowerTierList(user?.configs.admin2?.LOWER_TIER_ACCESS);
  };

  const handleUserMorph = async (id: string) => {
    const response: any = await morphInto(id);
    if (response) {
      checkCurrentUrlAfterMorph();
    }
  };

  const toggleDrawer = (open: boolean) => (event: any) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    dispatch(toggleRightSideDrawer(open));
    cleanKey();
  };
  const onSearchKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue: any = event.target.value;
    setSearchKey(newValue);

    if (newValue === '') {
      setLowerTierList(user?.configs.admin2?.LOWER_TIER_ACCESS);
    } else {
      const newList = new Array<OhsLowerTier>();
      if (newValue && user?.configs.admin2 && user.configs.admin2.LOWER_TIER_ACCESS) {
        user.configs.admin2.LOWER_TIER_ACCESS.forEach((item) => {
          if (
            item.access === true &&
            item.name &&
            item.name.toLowerCase().indexOf(newValue.toLowerCase()) > -1
          ) {
            newList.push(item);
          }
        });
      }
      setLowerTierList(newList);
    }
  };
  return (
    <Drawer anchor="right" open={dashboard.rightSideDrawerToggleOpen} onClose={toggleDrawer(false)}>
      <div className={clsx(classes.list)} role="presentation">
        <List>
          <Box m={1}>
            <img style={{ width: '64px' }} src={logo} alt="" />
          </Box>
          <Divider />
          <Box m={2}>
            <p>
              <b>Login to: </b>
            </p>
          </Box>
          <Divider />

          <Box m={2}>
            <p>
              <b>Workplaces</b>
            </p>
          </Box>
          <Box m={2}>
            <OutlinedInput
              sx={{ width: '100%', paddingRight: '0px' }}
              value={searchKey}
              onChange={onSearchKeyChange}
              placeholder="Search"
              endAdornment={
                <InputAdornment position="end">
                  {_.isNil(searchKey) || searchKey === '' ? (
                    <IconButton type="submit">
                      <SearchIcon />
                    </IconButton>
                  ) : (
                    <IconButton onClick={cleanKey}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              }
            />
          </Box>

          <div>
            {lowerTierList?.map((lowerTier: OhsLowerTier) =>
              lowerTier.disabled !== true && lowerTier.access === true ? (
                <ListItem button key={lowerTier._id} onClick={() => handleUserMorph(lowerTier._id)}>
                  <ListItemText primary={lowerTier.name} />
                </ListItem>
              ) : null
            )}
          </div>
        </List>
      </div>
    </Drawer>
  );
}

export default OhsRightSideDrawer;
