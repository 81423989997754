import React, { useState } from 'react';

import { Icon } from 'semantic-ui-react';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

import { getOhsMultiUserLoginOptionList } from 'login/multi/OhsMultiUserLoginOption';
import { useAppSelector } from 'app/hooks';
import { getOhsUser } from 'user/userSlice';
import { OhsMaterialIconButton } from 'global-components/buttons/OhsMaterialButton';
import OhsLogoutModal from 'login/OhsLogoutModal';

import OhsSwitchWorkplaceMenu from './OhsSwitchWorkplaceMenu';

const useStyles = makeStyles()((theme: Theme) => ({
  signOutButton: {
    marginLeft: theme.spacing(2),
  },
}));

export default function OhsLogoutButton() {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [logoutModalOpen, setLogoutModalOpen] = React.useState(false);
  const user = useAppSelector(getOhsUser);
  const userOptions = getOhsMultiUserLoginOptionList();
  const { classes } = useStyles();

  const handleClickLoginOutButton = (event: React.MouseEvent<HTMLElement>) => {
    if (userOptions.length > 1) {
      setAnchorEl(event.currentTarget);
    } else {
      setLogoutModalOpen(true);
    }
  };
  return (
    <>
      <OhsMaterialIconButton
        color="inherit"
        onClick={handleClickLoginOutButton}
        size="small"
        className={classes.signOutButton}
      >
        <Icon name="sign out" />
      </OhsMaterialIconButton>
      {anchorEl && <OhsSwitchWorkplaceMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />}
      <OhsLogoutModal user={user} open={logoutModalOpen} setLogoutModalOpen={setLogoutModalOpen} />
    </>
  );
}
