import {
  RequestType,
  OhsApiRequestName,
  ApiResListsType,
  ApiResponseType,
} from 'global-services/api/OhsApiModels';
import OhsApiRequest from 'global-services/api/OhsApiRequest';
import { ModuleType } from 'global-services/constants/OhsObject';

const formatSearchPayload = ({
  searchData,
  apiRequestName,
}: {
  searchData: any;
  apiRequestName?: OhsApiRequestName;
}): any => {
  switch (apiRequestName) {
    case OhsApiRequestName.OrgList:
      return {
        ...searchData,
        filter: {
          page: 1,
          count: false,
          modules: searchData.filter.modules || [],
          archived: false,
        },
      };
    case OhsApiRequestName.OrgWorkplaceList:
      return {
        ...searchData,
        filter: {
          page: 1,
          count: false,
          modules: searchData.filter.modules || [],
          workplaces: searchData.filter.workplaces || [],
          archived: false,
        },
      };
    default:
      return searchData;
  }
};

function globalModuleSearchHistory(
  searchData: any,
  apiRequestName?: OhsApiRequestName
): Promise<ApiResponseType<ApiResListsType<any[]>> | null> {
  return OhsApiRequest(RequestType.Module, ModuleType.Task, OhsApiRequestName.SearchForAggregate, {
    ...formatSearchPayload({ searchData, apiRequestName }),
  });
}

globalModuleSearchHistory.defaultProps = {
  apiRequestName: undefined,
};

export default globalModuleSearchHistory;
