import { OhsUser } from 'user/OhsUser';
import { ModuleConfig } from 'global-services/constants/OhsObject';
import OhsActionRoutesList from 'action/OhsActionRoutesList';
import OhsAdmin2RouteList from 'admin2/OhsAdmin2RouteList';
import OhsAssetRouteList from 'assets/OhsAssetRouteList';
import OhsChemicalRouteList from 'chemical/OhsChemicalRouteList';
import OhsCorporateRiskRouteList from 'corporate-risk/routes/OhsCorporateRiskRouteList';
import OhsContractorRouteList from 'contractor/routes/OhsContractorRouteList';
import OhsCorrespondenceRouteList from 'correspondence/OhsCorrespondenceRouteList';
import OhsDocumentsRouteList from 'documents/OhsDocumentsRouteList';
import OhsHumanResourceRouteList from 'human-resource/OhsHumanResourceRouteList';
import OhsIncidentRouteList from 'incident/OhsIncidentRouteList';
import OhsInspectionRouteList from 'inspection/OhsInspectionRouteList';
import OhsNoticeBoardRouteList from 'notice-board/OhsNoticeBoardRouteList';
import OhsReviewPlanRouteList from 'review-plan/OhsReviewPlanRouteList';
import OhsSafetyPlanRouteList from 'safety-plan/OhsSafetyPlanRouteList';
import OhsVisitorRouteList from 'visitor/OhsVisitorRouteList';

// module default routes, returns routes with permission
const moduleDefaultRoutes = (user: OhsUser) => {
  return {
    [ModuleConfig.Action]: {
      home: OhsActionRoutesList.home.permission(user) && OhsActionRoutesList.home.url,
      add: OhsActionRoutesList.add.permission(user) && OhsActionRoutesList.add.url,
    },
    [ModuleConfig.Asset]: {
      home: OhsAssetRouteList.home.permission(user) && OhsAssetRouteList.home.url,
      add: OhsAssetRouteList.add.permission(user) && OhsAssetRouteList.add.url,
    },
    [ModuleConfig.Chemical]: {
      home: OhsChemicalRouteList.home.permission(user) && OhsChemicalRouteList.home.url,
      add: OhsChemicalRouteList.add.permission(user) && OhsChemicalRouteList.add.url,
    },
    [ModuleConfig.Contractor]: {
      home: OhsContractorRouteList.home.permission(user) && OhsContractorRouteList.home.url,
      add: OhsContractorRouteList.add.permission(user) && OhsContractorRouteList.add.url,
    },
    [ModuleConfig.CorporateRisk]: {
      home: OhsCorporateRiskRouteList.home.permission(user) && OhsCorporateRiskRouteList.home.url,
      add: OhsCorporateRiskRouteList.add.permission(user) && OhsCorporateRiskRouteList.add.url,
    },
    [ModuleConfig.Correspondence]: {
      home: OhsCorrespondenceRouteList.home.permission(user) && OhsCorrespondenceRouteList.home.url,
    },
    [ModuleConfig.HR]: {
      home: OhsHumanResourceRouteList.home.permission(user) && OhsHumanResourceRouteList.home.url,
    },
    [ModuleConfig.ImportManager]: {
      home: OhsAdmin2RouteList.import.permission(user) && OhsAdmin2RouteList.import.url,
    },
    [ModuleConfig.Incident]: {
      home: OhsIncidentRouteList.home.permission(user) && OhsIncidentRouteList.home.url,
      add: OhsIncidentRouteList.add.permission(user) && OhsIncidentRouteList.add.url,
    },
    [ModuleConfig.Inspection]: {
      home: OhsInspectionRouteList.home.permission(user) && OhsInspectionRouteList.home.url,
    },
    [ModuleConfig.Noticeboard]: {
      home: OhsNoticeBoardRouteList.home.permission(user) && OhsNoticeBoardRouteList.home.url,
    },
    [ModuleConfig.ReviewPlan]: {
      home: OhsReviewPlanRouteList.home.permission(user) && OhsReviewPlanRouteList.home.url,
      add: OhsReviewPlanRouteList.add.permission(user) && OhsReviewPlanRouteList.add.url,
    },
    [ModuleConfig.SafetyPlan]: {
      home: OhsSafetyPlanRouteList.home.permission(user) && OhsSafetyPlanRouteList.home.url,
    },
    [ModuleConfig.Training]: {
      home:
        OhsHumanResourceRouteList.training.permission(user) && OhsHumanResourceRouteList.home.url,
    },
    [ModuleConfig.VersionDocument]: {
      home: OhsDocumentsRouteList.home.permission(user) && OhsDocumentsRouteList.home.url,
    },
    [ModuleConfig.Visitor]: {
      home: OhsVisitorRouteList.home.permission(user) && OhsVisitorRouteList.home.url,
    },
  };
};

// return module routes by tier
// returns add url if with create permission but not view
export const returnDefaultModuleRoutesByPermission = (user: OhsUser) => {
  const urlObj = moduleDefaultRoutes(user);

  const returnUrl = (moduleKey: ModuleConfig, homeParams: string = '') => {
    const module = (urlObj as any)[moduleKey];
    if (module.home) return `${module.home}${homeParams}`;
    if (module.add) return module.add;
    return '';
  };

  const homeUrl = {
    'core.tier.T1': {},
    'core.tier.T2': {
      vdoc: returnUrl(ModuleConfig.VersionDocument),
      import: returnUrl(ModuleConfig.ImportManager),
      inspection: returnUrl(ModuleConfig.Inspection, '?archived=false'),
      safetyplan: returnUrl(ModuleConfig.SafetyPlan, '?tab=tp'),
    },
    'core.tier.T3': {
      correspondence: returnUrl(ModuleConfig.Correspondence),
      action: returnUrl(ModuleConfig.Action),
      inspection: returnUrl(ModuleConfig.Inspection),
      chemical: returnUrl(ModuleConfig.Chemical),
      contractor: returnUrl(ModuleConfig.Contractor),
      vdoc: returnUrl(ModuleConfig.VersionDocument),
      hr: returnUrl(ModuleConfig.HR),
      training: returnUrl(ModuleConfig.Training, '?tab=tr'),
      incident: returnUrl(ModuleConfig.Incident),
      reviewplan: returnUrl(ModuleConfig.ReviewPlan, '?tab=li'),
      safetyplan: returnUrl(ModuleConfig.SafetyPlan),
      visitor: returnUrl(ModuleConfig.Visitor),
      noticeboard: returnUrl(ModuleConfig.Noticeboard),
      corporateRisk: returnUrl(ModuleConfig.CorporateRisk),
      asset: returnUrl(ModuleConfig.Asset),
    },
    'core.tier.T4': {
      correspondence: returnUrl(ModuleConfig.Correspondence),
      action: returnUrl(ModuleConfig.Action),
      inspection: returnUrl(ModuleConfig.Inspection),
      chemical: returnUrl(ModuleConfig.Chemical),
      contractor: returnUrl(ModuleConfig.Contractor),
      vdoc: returnUrl(ModuleConfig.VersionDocument),
      hr: returnUrl(ModuleConfig.HR),
      training: returnUrl(ModuleConfig.Training, '?tab=tr'),
      incident: returnUrl(ModuleConfig.Incident),
      reviewplan: returnUrl(ModuleConfig.ReviewPlan),
      safetyplan: returnUrl(ModuleConfig.SafetyPlan),
      visitor: returnUrl(ModuleConfig.Visitor),
      noticeboard: returnUrl(ModuleConfig.Noticeboard),
      corporateRisk: returnUrl(ModuleConfig.CorporateRisk),
      asset: returnUrl(ModuleConfig.Asset),
    },
    'core.tier.T5': { action: returnUrl(ModuleConfig.Action) },
  };

  return homeUrl;
};

export default returnDefaultModuleRoutesByPermission;
