import { ModuleType, TierType } from 'global-services/constants/OhsObject';
import { OhsTier } from 'global-services/constants/tier/OhsTier';

import { OhsMfaMethodSettings, OhsUser } from './OhsUser';
import OhsConfigurations from './configurations/OhsConfigurations';

interface OhsCurrentUserTier {
  _id: string;
  type: TierType;
  name: string;
  parent: {
    type: TierType;
    _id: string;
  };
  profile: {
    type: 'core.profile.tier';
    _id: string;
  };
  city: string | null;
  state: string;
  country: string;
  address: string;
  referenceId: string;
}
export interface OhsCurrentUser {
  type: ModuleType.User;
  _id: string;
  name: string;
  email: string;
  phone: string;
  phoneCountryCode: string;
  tier: OhsCurrentUserTier;
  configuration: OhsConfigurations[];
  lastConfigurationUpdate: string;
  user: Partial<OhsUser>;
}
export interface OhsModuleOption {
  name: string;
  key: ModuleType;
}

export interface OhsWorkplaceOption {
  name: string;
  id: string;
}

export interface OhsSelectOption {
  name: string;
  value: string;
}

export interface OhsOrgControlPayload {
  orgTier: OhsTier;
}

export interface OhsOrgControl {
  type: TierType;
  _id: string;
  userLimit: number;
  hrLimit: number;
  workplaceLimit: number;
  workerGroupLimit: number;
  multiuserCreate: boolean;
  xsiAccess: boolean;
  sms: {
    instant: boolean;
    scheduled: boolean;
  };
  push: {
    instant: boolean;
    scheduled: boolean;
  };
  sso: boolean | null;
  mfa: OhsMfaMethodSettings;
  bsonExportEnabled: boolean;
  users: {
    active: number;
    disabled: number;
  };
  multiusers: {
    active: number;
    disabled: number;
    distinctActive: number;
    distinctDisabled: number;
  };
  hr: {
    active: number;
    archived: number;
  };
}

export class OhsUserDataLocalStorage {
  creation: number;

  data: any;

  constructor(data: any) {
    this.data = data;
    this.creation = new Date().getTime();
  }
}

export const UserDataLocalStorageName = 'UserData';

export const UserDataFetchThreshHoldMs = 30 * 1000;
