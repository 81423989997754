import { OhsUser } from 'user/OhsUser';
import { ModuleRoute, ModuleUrl } from 'global-services/constants/OhsRoutes';

const vdocPermission = (user: OhsUser) => {
  return user?.configs?.vdoc?.PERMISSIONS.view || false;
};
const vdocSignOffPermission = (user: OhsUser) => {
  return user?.configs?.vdoc?.PERMISSIONS.signoff || false;
};
const createRoute = (path: string, permission: (user: OhsUser) => boolean) => ({
  path,
  url: `/${ModuleUrl.Documents}/${path}`,
  permission,
});

const OhsDocumentsRouteList = {
  [ModuleRoute.Home]: createRoute('home', vdocPermission),
  [ModuleRoute.Details]: createRoute(':id/view', vdocPermission),
  [ModuleRoute.Evidence]: createRoute(':docId/task/:taskId/completed', vdocPermission),
  [ModuleRoute.SignOff]: createRoute(':docId/task/:taskId/signoff/*', vdocSignOffPermission),
};

export default OhsDocumentsRouteList;
