import { OhsUser } from 'user/OhsUser';
import { withSignOffViewPermission } from 'global-services/OhsPermission';
import { ModuleUrl, ModuleRoute } from 'global-services/constants/OhsRoutes';
import { ModuleConfig } from 'global-services/constants/OhsObject';

const commonPermission = (user: OhsUser) => user?.configs?.hr?.PERMISSIONS.view || false;
const trainingPermission = (user: OhsUser) => user?.configs?.training?.PERMISSIONS.view || false;
const createRoute = (path: string, permission: (user: OhsUser) => boolean) => ({
  path,
  url: `/${ModuleUrl.HR}/${path}`,
  permission,
});

const OhsHumanResourceRouteList = {
  [ModuleRoute.Home]: createRoute('home', commonPermission),
  [ModuleRoute.Training]: createRoute('training/:id/view', trainingPermission),
  [ModuleRoute.Details]: createRoute(':id/view', commonPermission),
  [ModuleRoute.SignOff]: createRoute('training/:trainingId/task/:taskId/signoff', (user) =>
    withSignOffViewPermission(user, ModuleConfig.Training)
  ),
  [ModuleRoute.Evidence]: createRoute(
    'training/:trainingId/task/:taskId/completed',
    commonPermission
  ),
};

export default OhsHumanResourceRouteList;
