import { OhsUser, OhsUserLoginType, OhsUserMorphType, OhsUserPlanType } from 'user/OhsUser';

class OhsMixpanelPeople {
  $name: string;

  $email: string;

  OrganisationName: string;

  OrganisationId: string;

  Username: string;

  UserEmail: string;

  UserId: string;

  UserPlanType: OhsUserPlanType;

  LoginType: OhsUserLoginType;

  TierName: string;

  TierId: string;

  TierLevel: string;

  Morph: OhsUserMorphType;

  constructor(user: OhsUser | null) {
    this.OrganisationName = user?.org?.name ?? '';
    this.OrganisationId = user?.org?._id ?? '';
    this.Username = user?.name ?? '';
    this.UserEmail = user?.email.toLowerCase() ?? '';
    this.UserId = user?._id ?? '';
    this.UserPlanType = user?.level ?? 'paid';
    this.LoginType = user?.isSso === true ? 'Azure SSO' : 'Username Password';
    this.TierName = user?.tier.name ?? '';
    this.TierId = user?.tier._id ?? '';
    this.TierLevel = String(user?.tierNum ?? 0);
    this.Morph = user?.morphType ?? 'None';
    this.$email = this.UserEmail;
    this.$name = this.Username;
  }
}
export default OhsMixpanelPeople;
