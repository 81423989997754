import {
  ApiResListsType,
  ApiResponseType,
  OhsApiRequestName,
  OhsApiRequestOptions,
  RequestType,
} from 'global-services/api/OhsApiModels';
import { ModuleType, TierType } from 'global-services/constants/OhsObject';
import { OhsAllocationItem } from 'global-components/allocation/OhsAllocatableModels';
import { getCurrentTimezone } from 'global-services/OhsDataParse';
import OhsFetchAll from 'global-services/OhsFetchAll';
import { OhsHrLookup } from 'human-resource/models/OhsHumanResourceModels';
import { parseHrLookupList } from 'human-resource/OhsHumanResourceServices';
import { parseContractorLookupList } from 'contractor/OhsContractorService';
import OhsContractorLookup from 'contractor/models/OhsContractorLookUp';

import OhsApiRequest from '../global-services/api/OhsApiRequest';
import {
  AssetAllocListRes,
  OhsAssetListsFilterPayload,
  OhsOrgTabFilterPayload,
  OhsWorkplaceTabFilterPayload,
} from './models/OhsAssetModels';
import OhsAssetRecord from './models/OhsAssetRecord';
import parseListAllocations from './utils/OhsParseListAllocations';
import OhsAssetTaskRecord from './models/OhsAssetTaskRecord';

const getAssetLists = (
  filterInfo: OhsOrgTabFilterPayload | OhsWorkplaceTabFilterPayload | OhsAssetListsFilterPayload
): Promise<ApiResListsType<any[]> | null> => {
  return OhsApiRequest(RequestType.Module, ModuleType.Asset, OhsApiRequestName.List, {
    filter: filterInfo,
  });
};
export const getAssetAllocatedList = (assetListIDs: string[]): Promise<OhsAssetRecord[] | null> => {
  return OhsApiRequest(RequestType.Module, ModuleType.Asset, OhsApiRequestName.AllocatedList, {
    _ids: assetListIDs,
  });
};
export const getAssetWithAllocations = (
  filterInfo: OhsAssetListsFilterPayload | OhsWorkplaceTabFilterPayload | OhsOrgTabFilterPayload
): Promise<ApiResListsType<any[]> | null> => {
  const getAssetListsReq = async (): Promise<ApiResListsType<any[]> | null> => {
    const assetLists = await getAssetLists(filterInfo);

    if (assetLists && assetLists?.items.length === 0) {
      return new Promise((resolve) => {
        resolve(assetLists);
      });
    }
    // only check tier3 records
    const getIDs: string[] = [];
    assetLists?.items.forEach((item) => {
      if (item._id && item.tier.type === TierType.T3) getIDs.push(item._id);
    });
    if (getIDs && getIDs.length === 0) {
      return new Promise((resolve) => {
        resolve(assetLists);
      });
    }

    const allocatedList = await getAssetAllocatedList(getIDs);

    const assetListWithAllocations = parseListAllocations(
      assetLists,
      allocatedList
    ) as ApiResListsType<OhsAssetRecord[]> | null;

    return new Promise((resolve) => {
      resolve(assetListWithAllocations);
    });
  };

  return getAssetListsReq();
};

export const getAssetDetails = async (
  _id: string,
  disableErrorToast?: boolean
): Promise<OhsAssetRecord> => {
  const res: OhsAssetRecord | null = await OhsApiRequest(
    RequestType.Module,
    ModuleType.Asset,
    OhsApiRequestName.Fetch,
    { _id },
    { disableErrorToast }
  );
  return res || new OhsAssetRecord();
};
export const archiveAsset = async (payload: any, archived: boolean): Promise<any> => {
  const res: any = await OhsApiRequest(
    RequestType.Module,
    ModuleType.Asset,
    archived ? OhsApiRequestName.UnArchive : OhsApiRequestName.Archive,
    payload
  );
  return res;
};
export const deleteAsset = (assetId: string) => {
  return OhsApiRequest(RequestType.Module, ModuleType.Asset, OhsApiRequestName.Delete, {
    _id: assetId,
  });
};
export const allocateAsset = (
  _id: string,
  allocationItem: OhsAllocationItem | OhsAllocationItem[]
): Promise<any> => {
  const payload: any = {
    _id,
    to: [],
    tzDateCreated: getCurrentTimezone(),
  };
  if (Array.isArray(allocationItem)) {
    payload.to = allocationItem.map((item) => ({
      _id: item._id,
      type: item.type,
    }));
  } else {
    payload.to = [allocationItem];
  }
  return OhsApiRequest(RequestType.Module, ModuleType.Asset, OhsApiRequestName.Allocate, payload);
};
export const deAllocateAsset = (
  _id: string,
  allocationItem: OhsAllocationItem | OhsAllocationItem[]
): Promise<any> => {
  const payload: any = { _id, to: [], tz: getCurrentTimezone() };
  if (Array.isArray(allocationItem)) {
    payload.to = allocationItem.map((item) => ({
      _id: item._id,
      type: item.type,
    }));
  } else {
    payload.to = [allocationItem];
  }
  return OhsApiRequest(RequestType.Module, ModuleType.Asset, OhsApiRequestName.Deallocate, payload);
};
export const getAssetAllocationList = (
  assetId: string
): Promise<ApiResponseType<AssetAllocListRes> | null> => {
  return OhsApiRequest(RequestType.Module, ModuleType.Asset, OhsApiRequestName.AllocationList, {
    _id: assetId,
  });
};
export const getAssetRecord = async (_id: string): Promise<OhsAssetRecord> => {
  const res: OhsAssetRecord | null = await OhsApiRequest(
    RequestType.Module,
    ModuleType.Asset,
    OhsApiRequestName.Fetch,
    { _id }
  );
  return res || new OhsAssetRecord();
};
export const getAssetTaskRecord = async (
  _id: string,
  taskId: string,
  disableErrorToast?: boolean
): Promise<OhsAssetTaskRecord> => {
  const res: OhsAssetTaskRecord | null = await OhsApiRequest(
    RequestType.Module,
    ModuleType.Task,
    OhsApiRequestName.Fetch,
    {
      _id: taskId,
      for: {
        _id,
        type: ModuleType.Asset,
      },
    },
    { disableErrorToast }
  );
  return res || new OhsAssetTaskRecord();
};

export const setConfidential = async (_id: string, isConfidential: boolean): Promise<any> => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Asset,
    isConfidential ? OhsApiRequestName.ConfidentialSet : OhsApiRequestName.ConfidentialUnSet,
    {
      _id,
    }
  );
};

export const getHrList = (filter?: any): Promise<ApiResListsType<OhsHrLookup[]> | null> => {
  return OhsApiRequest(RequestType.Module, ModuleType.Asset, OhsApiRequestName.HRLookup, {
    filter,
  });
};
export const getHrLookupList = async () => {
  const res = await OhsFetchAll(getHrList, { archived: false });
  if (!res) return null;
  return parseHrLookupList(res?.items || []);
};

export const getContractorList = (
  filter?: any
): Promise<ApiResListsType<OhsContractorLookup[]> | null> => {
  return OhsApiRequest(RequestType.Module, ModuleType.Asset, OhsApiRequestName.ContractorLookup, {
    filter,
  });
};

export const getContractorLookupList = async () => {
  const res = await OhsFetchAll(getContractorList, { archived: false });
  if (!res) return null;
  return parseContractorLookupList(res || []);
};

export const editAssetService = (payload: any, options?: OhsApiRequestOptions) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Asset,
    OhsApiRequestName.Edit,
    payload,
    options
  );
};

export const addAssetService = (payload: any, options?: OhsApiRequestOptions) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Asset,
    OhsApiRequestName.Create,
    payload,
    options
  );
};

export const getAssetLinkedActionList = async (_id: string) => {
  const res: any = await OhsApiRequest(
    RequestType.Module,
    ModuleType.Asset,
    OhsApiRequestName.LinkedActions,
    {
      _id,
    }
  );
  return res;
};

export const signOffAssetRecord = (payload: any) =>
  OhsApiRequest(RequestType.Module, ModuleType.Asset, OhsApiRequestName.SignOff, payload);

export const signOffAssetCancelSave = (assetId: string, taskId: string) => {
  const payload = {
    _id: assetId,
    task: {
      _id: taskId,
    },
  };
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Asset,
    OhsApiRequestName.SignOffRemoveSave,
    payload
  );
};
export default getAssetLists;
