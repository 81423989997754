import {
  ApiResListsType,
  ApiResponseType,
  OhsApiRequestName,
  RequestType,
} from 'global-services/api/OhsApiModels';
import { ModuleType } from 'global-services/constants/OhsObject';

import OhsApiRequest from '../global-services/api/OhsApiRequest';
import {
  AssignTaskToFilter,
  BookmarkTaskPayload,
  FetchTaskListTypes,
  OhsTaskListsFilterPayload,
  TaskAssignStatus,
  TaskAssignStatusFilter,
  TaskListsRecord,
} from './OhsTaskTypes';

const getTaskLists = (
  taskInfo: FetchTaskListTypes,
  filterInfo: OhsTaskListsFilterPayload
): Promise<ApiResponseType<ApiResListsType<TaskListsRecord[]>> | null> => {
  return OhsApiRequest(RequestType.Module, ModuleType.Task, taskInfo.listType, {
    complete: taskInfo.complete,
    filter: filterInfo,
  });
};
export const getTaskAssignUserStatusList = (
  recordId: string,
  recordType: ModuleType,
  taskId: string
) => {
  return OhsApiRequest(RequestType.Module, ModuleType.Task, OhsApiRequestName.AssignTo, {
    payload: {
      _id: taskId,
      for: {
        _id: recordId,
        type: recordType,
      },
      to: {
        type: 'core.user',
        // TODO - add proper id
        _id: '',
      },
    },
  });
};

export const getAssignStatus = (filterInfo: TaskAssignStatusFilter): any => {
  return OhsApiRequest(RequestType.Module, ModuleType.Task, OhsApiRequestName.AssignStatus, {
    ...filterInfo,
  }) as Promise<TaskAssignStatus[]>;
};

export const getAssignAggregate = (
  idLists: TaskAssignStatusFilter[]
): Promise<ApiResponseType<{ items: TaskAssignStatus[] }> | null> => {
  return OhsApiRequest(RequestType.Module, ModuleType.Task, OhsApiRequestName.AssignAggregate, {
    _ids: idLists,
  });
};

export const getBookmarkListByTask = (
  idLists: string[]
): Promise<ApiResponseType<{ items: any[] }> | null> => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Bookmark,
    OhsApiRequestName.BookmarkedListByTask,
    {
      _ids: idLists,
    }
  );
};

export const assignTaskTo = (assignedToinfo: AssignTaskToFilter) => {
  return OhsApiRequest(RequestType.Module, ModuleType.Task, OhsApiRequestName.AssignTo, {
    ...assignedToinfo,
  });
};

export const unAssignTaskTo = (assignedToinfo: AssignTaskToFilter) => {
  return OhsApiRequest(RequestType.Module, ModuleType.Task, OhsApiRequestName.UnAssignTo, {
    ...assignedToinfo,
  });
};

export const fetchTaskListForModule = (
  moduleType: ModuleType,
  _id: string,
  filter?: OhsTaskListsFilterPayload
) => {
  return OhsApiRequest(RequestType.Module, ModuleType.Task, OhsApiRequestName.ListFor, {
    _id,
    type: moduleType,
    filter: {
      page: 1,
      sort: {
        order: -1,
        key: '_id',
      },
      ...filter,
    },
  }) as Promise<any[]>;
};

export const listActiveTaskFor = (id: string | string[]) => {
  let idList = [];

  if (typeof id === 'string') {
    idList = [id];
  } else {
    idList = id;
  }
  return OhsApiRequest(RequestType.Module, ModuleType.Task, OhsApiRequestName.ListActive, {
    _ids: idList,
  }) as Promise<any[]>;
};

export const listActiveTaskForModule = async (filter?: any) => {
  const taskList: any = await OhsApiRequest(
    RequestType.Module,
    ModuleType.Task,
    OhsApiRequestName.List,
    {
      filter,
    }
  );
  return taskList?.result as Promise<ApiResListsType<TaskListsRecord[]>>;
};

export const getFlaggedTaskLists = (
  complete: boolean,
  filterInfo: OhsTaskListsFilterPayload
): Promise<ApiResponseType<ApiResListsType<TaskListsRecord[]>> | null> => {
  return OhsApiRequest(RequestType.Module, ModuleType.Task, OhsApiRequestName.BookmarkedList, {
    complete,
    filter: filterInfo,
  });
};

export const bookmarkSet = (bookmarkInfo: BookmarkTaskPayload) => {
  return OhsApiRequest(RequestType.Module, ModuleType.Bookmark, OhsApiRequestName.Set, {
    ...bookmarkInfo,
  });
};

export default getTaskLists;
