import React from 'react';

import OhsModal from 'global-components/modal/OhsModal';

import OhsMfaVerifyFlow from './OhsMfaVerifyFlow';

interface Props {
  open: boolean;
  title: string;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSuccess: (methodId?: string) => void;
  onClose?: () => void;
}

function OhsMfaEnrollModal(props: Props) {
  const [currentStep, setCurrentStep] = React.useState(0);
  return (
    <OhsModal
      open={props.open}
      title={props.title}
      setModalOpenFunc={props.setModalOpen}
      maxWidth="xs"
      headerTextColor="black"
      headerColor="#fafafa"
      ContentNode={<OhsMfaVerifyFlow setCurrentStep={setCurrentStep} onSuccess={props.onSuccess} />}
      onClose={currentStep === 1 ? props.onClose : undefined}
    />
  );
}

OhsMfaEnrollModal.defaultProps = {
  onClose: undefined,
};

export default OhsMfaEnrollModal;
