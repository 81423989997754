import _ from 'lodash';

import OhsApiRequest from 'global-services/api/OhsApiRequest';
import { ModuleType, TierType } from 'global-services/constants/OhsObject';
import {
  ApiResListsType,
  ApiResponseType,
  OhsApiRequestName,
  RequestType,
} from 'global-services/api/OhsApiModels';
import { getCurrentTimezone } from 'global-services/OhsDataParse';
import { OhsAllocationItem } from 'global-components/allocation/OhsAllocatableModels';
import OhsDocumentsListsRecord from 'documents/models/OhsDocumentsRecord';
import { getActionLinksByIds } from 'action/OhsActionServices';
import { getAttachmentList } from 'global-components/form/fields/attachments/OhsAttachmentServices';

import OhsInspectionRecord from './models/OhsInspectionRecord';
import {
  InspectionAllocListRes,
  AllocatedListType,
  OhsInspectionFilterPayload,
  OhsInspectionScheduleFilterPayload,
  OhsAvailableInspectionFilterPayload,
  OhsInspectionStart,
  OhsInspectionOneOffChild,
  OhsInspectionHistoryFilterPayload,
  OhsInspectionRootState,
} from './OhsInspectionModels';
import parseListAllocations from './register/utils/OhsParseListAllocations';
import OhsInspectionTaskRecord from './models/OhsInspectionTaskRecord';

export const getInspection = async (
  _id: string,
  disableErrorToast?: boolean
): Promise<OhsInspectionRecord> => {
  const res: OhsInspectionRecord | null = await OhsApiRequest(
    RequestType.Module,
    ModuleType.Inspection,
    OhsApiRequestName.Fetch,
    { _id },
    { disableErrorToast }
  );
  return res || new OhsInspectionRecord();
};

export const getInspectionList = async (
  filterInfo: OhsInspectionFilterPayload,
  orgAdminTierId?: string
): Promise<ApiResListsType<any[]> | null> => {
  const payload: any = {
    filter: filterInfo,
  };
  if (orgAdminTierId) {
    payload.orgAdminTier = {
      type: TierType.T2,
      _id: orgAdminTierId,
    };
  }
  const response: any = await OhsApiRequest(
    RequestType.Module,
    ModuleType.Inspection,
    OhsApiRequestName.List,
    payload
  );
  return response;
};

export const getInspectionScheduleList = async (
  filterInfo: OhsInspectionScheduleFilterPayload
): Promise<any | null> => {
  return OhsApiRequest(RequestType.Module, ModuleType.Inspection, OhsApiRequestName.ListScheduled, {
    filter: filterInfo,
  });
};

export const getInspectionHistoryList = async (
  filterInfo: OhsInspectionHistoryFilterPayload,
  recordId: string
): Promise<any | null> => {
  return OhsApiRequest(RequestType.Module, ModuleType.Inspection, OhsApiRequestName.TaskAggregate, {
    filter: filterInfo,
    _id: recordId,
    matrix: false,
  });
};

export const getAvailableInspectionList = async (
  filter: OhsAvailableInspectionFilterPayload
): Promise<ApiResListsType<any[]> | null> => {
  const response: any = await OhsApiRequest(
    RequestType.Module,
    ModuleType.Inspection,
    OhsApiRequestName.ListAvailable,
    { filter }
  );
  return response;
};

export const archiveInspection = (inspectionId: string, archived: boolean) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Inspection,
    archived ? OhsApiRequestName.Archive : OhsApiRequestName.UnArchive,
    {
      _id: inspectionId,
    }
  );
};

export const deleteInspection = (inspectionId: string) => {
  return OhsApiRequest(RequestType.Module, ModuleType.Inspection, OhsApiRequestName.Delete, {
    _id: inspectionId,
  });
};

// Only used in scheduled tab
export const deleteOneoffInspectionTask = (taskId: string) => {
  return OhsApiRequest(RequestType.Module, ModuleType.Inspection, OhsApiRequestName.DeleteTask, {
    _id: taskId,
  });
};

export const getInspectionAllocatedList = (
  inspectionListIDs: string[]
): Promise<AllocatedListType[] | null> => {
  return OhsApiRequest(RequestType.Module, ModuleType.Inspection, OhsApiRequestName.AllocatedList, {
    _ids: inspectionListIDs,
  });
};

export const getInspectionListWithAllocations = (
  inspectionState: OhsInspectionRootState,
  inspectionFilters: OhsInspectionFilterPayload,
  inspectionSearch: () => any
): Promise<ApiResListsType<any[]> | null> => {
  const hasGlobalSearch = inspectionState.globalSearch.searchInfo.searchKey !== '';
  const getInspectionListReq = async (): Promise<ApiResListsType<any[]> | null> => {
    const inspectionList = hasGlobalSearch
      ? await inspectionSearch()
      : await getInspectionList(inspectionFilters);

    // exit if list result is empty
    if (inspectionList && inspectionList?.items.length === 0) {
      return new Promise((resolve) => {
        resolve(inspectionList);
      });
    }

    // get allocated records from inspection _ids
    const getIDs: string[] = [];
    inspectionList?.items.forEach((item: OhsInspectionRecord) => {
      if (item.tier?.type === TierType.T3 && item._id) getIDs.push(item._id);
    });
    if (getIDs && getIDs.length === 0) {
      return new Promise((resolve) => {
        resolve(inspectionList);
      });
    }
    const allocatedList = await getInspectionAllocatedList(getIDs);

    // map allocated records to inspection list
    const inspectionListWithAllocations = parseListAllocations(
      inspectionList,
      allocatedList
    ) as ApiResListsType<OhsInspectionRecord[]> | null;

    return new Promise((resolve) => {
      resolve(inspectionListWithAllocations);
    });
  };

  return getInspectionListReq();
};

export const getInspectionAllocationList = (
  inspectionId: string
): Promise<ApiResponseType<InspectionAllocListRes> | null> => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Inspection,
    OhsApiRequestName.AllocationList,
    {
      _id: inspectionId,
    }
  );
};

export const allocateInspection = (
  _id: string,
  allocationItem: OhsAllocationItem | OhsAllocationItem[]
): Promise<any> => {
  return OhsApiRequest(RequestType.Module, ModuleType.Inspection, OhsApiRequestName.Allocate, {
    _id,
    to: allocationItem,
    tz: getCurrentTimezone(),
  });
};

export const deAllocateInspection = (
  _id: string,
  allocationItem: OhsAllocationItem | OhsAllocationItem[],
  isOneOff: boolean
): Promise<any> => {
  const payload: any = {
    _id,
    to: Array.isArray(allocationItem)
      ? allocationItem.map((item) => ({
          ...item,
          ...(isOneOff && { deleteActiveTask: true }),
        }))
      : {
          ...allocationItem,
          ...(isOneOff && { deleteActiveTask: true }),
        },
    tz: getCurrentTimezone(),
  };

  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Inspection,
    OhsApiRequestName.Deallocate,
    payload
  );
};

export const getInspectionCopyList = (filterInfo: any): Promise<ApiResListsType<any[]> | null> => {
  return OhsApiRequest(RequestType.Module, ModuleType.Inspection, OhsApiRequestName.Copylist, {
    filter: filterInfo,
  });
};

export const copyInspection = (payload: OhsInspectionRecord): Promise<any | null> => {
  return OhsApiRequest(RequestType.Module, ModuleType.Inspection, OhsApiRequestName.Copy, payload);
};

export const cloneInspection = (payload: OhsInspectionRecord): Promise<any | null> => {
  return OhsApiRequest(RequestType.Module, ModuleType.Inspection, OhsApiRequestName.Clone, payload);
};

export const startInspection = (payload: OhsInspectionStart) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Inspection,
    OhsApiRequestName.SignOffStart,
    payload
  );
};

export const createNewOneOffChild = (payload: OhsInspectionOneOffChild): Promise<any> => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Inspection,
    OhsApiRequestName.CreateTask,
    payload
  );
};

const trimTemplate = (template: OhsInspectionRecord) => {
  const newTemplate = { ...template };
  // timezone
  newTemplate.tzDateCreated = getCurrentTimezone();
  // trim Links
  const newLinks: any[] = [];
  newTemplate.templateMeta?.links.forEach((link: any) => {
    newLinks.push({ _id: link._id, type: link.type });
  });
  if (newTemplate.templateMeta) {
    newTemplate.templateMeta.links = newLinks;

    newTemplate.templateMeta.control.signatures = parseInt(
      newTemplate.templateMeta?.control.signatures,
      10
    );

    newTemplate.templateMeta.control.geoloc = parseInt(newTemplate.templateMeta.control.geoloc, 10);

    if (!newTemplate.templateMeta.labels.inspection)
      delete newTemplate.templateMeta.labels.inspection;
    if (!newTemplate.templateMeta.labels.location) delete newTemplate.templateMeta.labels.location;
    if (!newTemplate.templateMeta.labels.lead) delete newTemplate.templateMeta.labels.lead;
    if (!newTemplate.templateMeta.labels.team) delete newTemplate.templateMeta.labels.team;
    if (!newTemplate.templateMeta.labels.notes) delete newTemplate.templateMeta.labels.notes;
    if (!newTemplate.templateMeta.labels.locationPresets)
      delete newTemplate.templateMeta.labels.locationPresets;
  }

  // trim customised fields

  return newTemplate;
};

export const createNewTemplate = (
  inspection: OhsInspectionRecord
): Promise<OhsInspectionRecord | null> => {
  const payload = trimTemplate(inspection);
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Inspection,
    OhsApiRequestName.Create,
    payload
  );
};
export const editInspectionTemplate = (
  inspection: OhsInspectionRecord
): Promise<OhsInspectionRecord | null> => {
  const payload = trimTemplate(inspection);
  return OhsApiRequest(RequestType.Module, ModuleType.Inspection, OhsApiRequestName.Edit, payload);
};

export const editTempProperty = (
  act: string,
  objArray: any,
  proName: string,
  type: string,
  defaultValue: string
) => {
  if (act === 'add') {
    objArray.forEach((obj: any) => {
      if (defaultValue != null) {
        obj[proName] = defaultValue;
      } else if (type === 'bool') {
        obj[proName] = false;
      } else if (type === 'string') {
        obj[proName] = '';
      } else {
        obj[proName] = null;
      }
    });
  } else if (act === 'remove') {
    objArray.forEach(objArray, (obj: any) => {
      delete obj[proName];
    });
  } else {
    console.error('action error');
  }
};

export const getInspectionTaskRecord = async (
  _id: string,
  taskId: string,
  disableErrorToast?: boolean
): Promise<OhsInspectionTaskRecord> => {
  const res: OhsInspectionTaskRecord | null = await OhsApiRequest(
    RequestType.Module,
    ModuleType.Task,
    OhsApiRequestName.Fetch,
    {
      _id: taskId,
      for: {
        _id,
        type: ModuleType.Inspection,
      },
    },
    { disableErrorToast }
  );
  if (res && res.templateExecution) {
    const actionQueryIds: string[] = [];
    const attachmentIdList: string[] = [];
    for (let index = 0; index < res.templateExecution.sections.length; index += 1) {
      const section = res.templateExecution.sections[index];
      for (let indexQuestion = 0; indexQuestion < section.questions.length; indexQuestion += 1) {
        const question = section.questions[indexQuestion];
        if (question && question.answer) {
          if (question.answer.links)
            for (
              let answerIndex = 0;
              answerIndex < question.answer.links.length;
              answerIndex += 1
            ) {
              const action = question.answer.links[answerIndex];

              actionQueryIds.push(action._id);
            }

          if (question.answer.attachments)
            for (
              let answerIndex = 0;
              answerIndex < question.answer.attachments.length;
              answerIndex += 1
            ) {
              const item = question.answer.attachments[answerIndex];
              if (item._id && attachmentIdList.includes(item._id) !== true)
                attachmentIdList.push(item._id);
            }
        }
      }
    }
    const actionList = actionQueryIds.length ? await getActionLinksByIds(actionQueryIds) : [];
    const attachmentList = attachmentIdList.length ? await getAttachmentList(attachmentIdList) : [];

    for (let index = 0; index < res.templateExecution.sections.length; index += 1) {
      const section = res.templateExecution.sections[index];
      for (let indexQuestion = 0; indexQuestion < section.questions.length; indexQuestion += 1) {
        const question = section.questions[indexQuestion];
        if (question && question.answer) {
          if (question.answer.links)
            for (let linkIndex = 0; linkIndex < question.answer.links.length; linkIndex += 1) {
              const actionId = question.answer.links[linkIndex]._id;

              const queryAction = _.find(actionList, { _id: actionId });
              if (queryAction) question.answer.links[linkIndex] = queryAction;
            }

          if (attachmentList.length && question.answer.attachments)
            for (
              let answerIndex = 0;
              answerIndex < question.answer.attachments.length;
              answerIndex += 1
            ) {
              const item = question.answer.attachments[answerIndex];
              const downloadAttachment = _.find(attachmentList, { _id: item._id });
              if (downloadAttachment) question.answer.attachments[answerIndex] = downloadAttachment;
            }
        }
      }
    }
  }
  return res || new OhsInspectionTaskRecord();
};

export const getInspectionTaskLinkedVdocs = async (
  _id: string,
  taskId: string
): Promise<OhsDocumentsListsRecord[]> => {
  const res: OhsDocumentsListsRecord[] | null = await OhsApiRequest(
    RequestType.Module,
    ModuleType.Inspection,
    OhsApiRequestName.FetchTaskLinkedVdocs,
    {
      _id,
      task: {
        _id: taskId,
      },
    }
  );
  return res || [];
};

export const getInspectionLinkedVdocs = async (_id: string): Promise<OhsDocumentsListsRecord[]> => {
  const res: OhsDocumentsListsRecord[] | null = await OhsApiRequest(
    RequestType.Module,
    ModuleType.Inspection,
    OhsApiRequestName.FetchLinkedVdoc,
    {
      _id,
    }
  );
  return res || [];
};

export const signOffInspection = (payload: any) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Inspection,
    OhsApiRequestName.SignOff,
    payload
  );
};

// matrix === true returns history list of archived record
// matrix === false returns history list with cumulativeScorePercentage
export const getInspectionTasksAggregate = (_id: string, matrix: boolean) => {
  return OhsApiRequest(RequestType.Module, ModuleType.Inspection, OhsApiRequestName.TaskAggregate, {
    _id,
    matrix,
  });
};
