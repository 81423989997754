import React, { ReactNode } from 'react';

import { Box, Stack } from '@mui/material';
import { ButtonGroup, SemanticCOLORS, SemanticICONS } from 'semantic-ui-react';
import { makeStyles } from 'tss-react/mui';
import { SwitchProps } from '@mui/material/Switch';

import ButtonWithTooltips from 'global-components/buttons/OhsButtonWithTooltip';
import OhsExportButton from 'global-components/buttons/OhsExportButton';
import OhsExportAggregateButton from 'global-components/buttons/OhsExportAggregateButton';
import { useAppSelector } from 'app/hooks';
import { getOhsUser } from 'user/userSlice';
import { getCurrentURLModuleConfig } from 'user/OhsUserServices';
import OhsTooltip, { OhsTooltipProps } from 'global-components/form/components/OhsTooltip';
import OhsSwitch from 'global-components/switch/OhsSwitch';

import OhsHeaderButton from './table/rowButtons/OhsHeaderButton';
import OhsDropdownButton from '../buttons/dropdownWithButton/OhsDropdownButton';

const useStyles = makeStyles()(() => ({
  actionButtons: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row-reverse',
  },
}));

interface Props {
  permissionOverride?: boolean; // force conditions to only follow permission prop
  dropdownButton?: Array<{
    name: string;
    permission: boolean;
    options: Array<{
      name: string;
      key: string;
      description?: string;
      icon?: SemanticICONS;
      isButton?: boolean;
    }>;
    func: (key: string) => void;
    icon?: SemanticICONS;
    color?: SemanticCOLORS;
    disable?: boolean;
  }>;
  viewButton?: { func: () => void; name?: string; permission: boolean; icon?: SemanticICONS };
  switchButton?: { tooltipProp?: OhsTooltipProps; permission?: boolean; switchProp?: SwitchProps };
  createButton?: { func: () => void; name?: string; permission: boolean; disable?: boolean };
  openAllocateButton?: { func: () => void; name?: string; permission: boolean };
  archiveButton?: { func: () => void; name?: string };
  editButton?: { func: () => void; name?: string; permission: boolean; disable?: boolean };
  moveButton?: { func: () => void; name?: string; permission: boolean; disable?: boolean };
  openExcelModal?: () => void;
  exportAggregate?: () => void;
  openCopyButton?: { func: () => void; name?: string; permission: boolean };
  signoffButton?: { func: () => void; name?: string; canSignOff: boolean };
  assignButton?: { func: () => void; permission: boolean };
  reviewTask?: { func: () => void; name?: string; permission: boolean };
  iconButton?: { func: () => void; icon: SemanticICONS; color?: SemanticCOLORS };
  searchButton?: {
    func: () => void;
    icon: SemanticICONS;
    color: SemanticCOLORS;
    permission?: boolean;
  };
  deleteButton?: { func: () => void; permission: boolean };
  exportPDFButton?: { func: () => void; name?: string; permission: boolean };
  segregationButton?: { func: () => void; name: string; text: string; permission: boolean };
  customRender?: () => ReactNode;
  saveButton?: { func: () => void; permission: boolean; disable?: boolean };
  viewAggregateButton?: { func: () => void; permission: boolean };
}

export default function OhsModuleHeaderButtons(props: Props) {
  const { classes } = useStyles();
  const user = useAppSelector(getOhsUser);
  const moduleConfig = user?.configs && getCurrentURLModuleConfig(user?.configs);

  const isTier1 = () => user?.tierNum === 1;

  return (
    <Box className={classes.actionButtons}>
      <ButtonGroup>
        {props.iconButton && (
          <OhsHeaderButton
            color={props.iconButton.color ? props.iconButton.color : 'blue'}
            func={props.iconButton.func}
            name=""
            icon={props.iconButton.icon}
          />
        )}
        {props.searchButton && props.searchButton.permission !== false && (
          <OhsHeaderButton
            color={props.searchButton.color}
            func={props.searchButton.func}
            name=""
            icon={props.searchButton.icon}
          />
        )}
        {props.dropdownButton?.map(
          (dropdownButton) =>
            dropdownButton.permission && (
              <OhsDropdownButton
                handleSelect={dropdownButton.func}
                title={dropdownButton.name}
                icon={dropdownButton.icon}
                options={dropdownButton.options}
                color={dropdownButton.color}
              />
            )
        )}
        {props.viewButton && (
          <OhsHeaderButton
            color="blue"
            func={props.viewButton.func}
            name={props.viewButton.name ? props.viewButton.name : 'View'}
            icon={props.viewButton.icon ? props.viewButton.icon : 'align justify'}
            data-testid="header-button-view"
          />
        )}
        {props.exportPDFButton && props.exportPDFButton.permission && (
          <OhsHeaderButton
            color="green"
            func={props.exportPDFButton.func}
            name={props.exportPDFButton.name ? props.exportPDFButton.name : 'Export PDF'}
            icon="download"
          />
        )}
        {props.reviewTask && props.reviewTask?.permission === true && (
          <OhsHeaderButton
            color="blue"
            func={props.reviewTask.func}
            name={props.reviewTask.name ? props.reviewTask.name : 'Set Review'}
            icon="tasks"
          />
        )}
        {props.segregationButton && props.segregationButton.permission && (
          <ButtonWithTooltips tooltipMsg={props.segregationButton.text}>
            <OhsHeaderButton
              color="yellow"
              func={props.segregationButton.func}
              name={props.segregationButton.name}
              icon="lab"
            />
          </ButtonWithTooltips>
        )}
        {props.editButton &&
          (props.editButton?.permission === true || props.permissionOverride || isTier1()) && (
            <ButtonWithTooltips
              tooltipMsg="Edit is currently disable until saved task has been signed-off."
              disabled={!props.editButton?.disable}
            >
              <Stack>
                <OhsHeaderButton
                  color="blue"
                  func={props.editButton.func}
                  name={props.editButton.name ? props.editButton.name : 'Edit'}
                  icon="edit"
                  disabled={!!props.editButton?.disable}
                  data-testid="header-button-edit"
                />
              </Stack>
            </ButtonWithTooltips>
          )}
        {props.createButton &&
          props.createButton.permission &&
          ((moduleConfig &&
            moduleConfig.PERMISSIONS &&
            (moduleConfig.PERMISSIONS.create === true ||
              moduleConfig.PERMISSIONS.create_prose === true)) ||
            isTier1() ||
            props.permissionOverride) && (
            <OhsHeaderButton
              color="green"
              func={props.createButton.func}
              name={props.createButton.name ? props.createButton.name : moduleConfig?.TITLE ?? ''}
              icon="plus"
              disabled={!!props.createButton?.disable}
            />
          )}
        {props.moveButton && props.moveButton.permission && (
          <OhsHeaderButton
            color="blue"
            func={props.moveButton.func}
            name={props.moveButton.name ? props.moveButton.name : 'Move'}
            icon="exchange"
          />
        )}
        {props.openAllocateButton &&
          props.openAllocateButton?.permission === true &&
          (props.permissionOverride || moduleConfig?.PERMISSIONS.allocate === true) && (
            <OhsHeaderButton
              color="green"
              func={props.openAllocateButton.func}
              name="Allocate"
              icon="retweet"
            />
          )}
        {props.openCopyButton &&
          props.openCopyButton?.permission === true &&
          moduleConfig &&
          (props.permissionOverride || moduleConfig?.PERMISSIONS.copy === true) && (
            <OhsHeaderButton
              color="green"
              func={props.openCopyButton.func}
              name={
                props.openCopyButton.name ? props.openCopyButton.name : `Copy ${moduleConfig.TITLE}`
              }
              icon="copy"
            />
          )}
        {props.signoffButton && props.signoffButton.canSignOff && (
          <OhsHeaderButton
            color="green"
            func={props.signoffButton.func}
            name={props.signoffButton.name ? props.signoffButton.name : 'Sign-off'}
            icon="signup"
          />
        )}
        {props.assignButton &&
          props.assignButton?.permission === true &&
          (props.permissionOverride || moduleConfig?.PERMISSIONS.assign_task === true) && (
            <OhsHeaderButton
              color="teal"
              func={props.assignButton.func}
              name="Assign"
              icon="user"
            />
          )}
        {props.archiveButton &&
          moduleConfig &&
          moduleConfig.PERMISSIONS &&
          (moduleConfig.PERMISSIONS.archive === true ||
            moduleConfig.PERMISSIONS.archive_prose === true ||
            props.permissionOverride) && (
            <OhsHeaderButton
              color="teal"
              func={props.archiveButton.func}
              name={props.archiveButton.name ?? ''}
              icon="exchange"
            />
          )}
        {props.deleteButton &&
          props.deleteButton?.permission === true &&
          (props.permissionOverride || moduleConfig?.PERMISSIONS.delete === true) && (
            <OhsHeaderButton
              color="red"
              func={props.deleteButton.func}
              name="Delete"
              icon="trash"
            />
          )}
        {props.saveButton && props.saveButton.permission && (
          <OhsHeaderButton color="blue" func={props.saveButton.func} name="Save" icon="save" />
        )}
        {props.viewAggregateButton && props.viewAggregateButton.permission && (
          <OhsHeaderButton
            color="blue"
            func={props.viewAggregateButton.func}
            name="View Aggregate"
            icon="th"
          />
        )}
        {props.openExcelModal && <OhsExportButton onClick={props.openExcelModal} />}
        {props.exportAggregate && <OhsExportAggregateButton onClick={props.exportAggregate} />}
        {props.customRender && props.customRender()}
      </ButtonGroup>
      {props.switchButton &&
        !!(props.switchButton?.permission ?? true) &&
        (props.switchButton?.tooltipProp ? (
          <OhsTooltip {...props.switchButton.tooltipProp}>
            <Box>
              <OhsSwitch {...props.switchButton.switchProp} />
            </Box>
          </OhsTooltip>
        ) : (
          <OhsSwitch {...props.switchButton.switchProp} />
        ))}
    </Box>
  );
}

OhsModuleHeaderButtons.defaultProps = {
  permissionOverride: false,
  viewButton: undefined,
  dropdownButton: undefined,
  createButton: undefined,
  moveButton: undefined,
  openAllocateButton: undefined,
  archiveButton: undefined,
  editButton: undefined,
  openExcelModal: undefined,
  exportAggregate: undefined,
  openCopyButton: undefined,
  signoffButton: undefined,
  assignButton: undefined,
  reviewTask: undefined,
  iconButton: undefined,
  searchButton: undefined,
  deleteButton: undefined,
  exportPDFButton: undefined,
  segregationButton: undefined,
  customRender: undefined,
  saveButton: undefined,
  viewAggregateButton: undefined,
  switchButton: undefined,
};
