import React, { ReactNode, useEffect } from 'react';

import { Box, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';
import moment from 'moment';

import { SmallScreenWidthBreakpoint } from 'global-components/theme/OhsLayoutConstants';
import useWindowSize from 'global-components/screenSize/useWindowSize';
import { getOhsUser } from 'user/userSlice';
import { useAppSelector } from 'app/hooks';

interface StyleTheme extends Theme, DateColumnPropTypes {}

interface DateColumnPropTypes {
  children: ReactNode;
  dueDate?: string | number | Date;
  issueDate?: string | number | Date;
}

const useStyles = makeStyles<StyleTheme>()(() => ({
  root: {
    fontSize: '14px',
    paddiing: '0',
    fontWeight: 'bold',
  },
  dateColorBoxCell: {
    width: 'auto',
  },
  boxed: {
    fontSize: '12px',
    padding: '4px 20px',
    textAlign: 'center',
    color: 'white',
    borderRadius: '4px',
    fontWeight: 'bold',
    width: 'max-content',
  },
  mobile: {
    padding: '0',
    margin: '0 5px 0 0',
    fontWeight: 'bold',
    fontSize: '14px',
  },
  boxedMobile: {
    padding: '0 20px',
    marginRight: '5px',
    fontSize: '12px',
    borderRadius: '4px',
    color: 'white',
  },
  withDiff: {
    margin: '0 2px',
  },
  dateColorBoxWrap: {
    display: 'flex',
    alignItems: 'center',
  },
}));

function getDateColor(issueDate: string, frequencyValue: number, frequencyKey: string) {
  const timeLimit = new Date(issueDate);
  if (frequencyKey === 'years') {
    timeLimit.setFullYear(timeLimit.getFullYear() + frequencyValue);
  } else if (frequencyKey === 'months') {
    timeLimit.setMonth(timeLimit.getMonth() + frequencyValue);
  }

  const now = moment(new Date()).startOf('day');
  const due = moment(new Date(timeLimit)).startOf('day');
  const diff = moment(due).diff(now, 'days');
  if (diff >= 0) {
    return '#22BA46';
  }
  return '#DB2828';
}

export default function OhsChemicalDateColor(props: any) {
  const screenSize = useWindowSize();
  const user = useAppSelector(getOhsUser);
  const reviewFrequency = user.configs.chemical?.SDS_REVIEW_FREQUENCY;
  const { classes } = useStyles(props);
  const [dynamicColors, setDynamicClasses] = React.useState('');
  const { dateDue, noBackgroundBox } = props;

  const isMobile = screenSize.width < SmallScreenWidthBreakpoint;
  const boxstyle = noBackgroundBox ? 'color' : 'backgroundColor';
  const boxedMobile = isMobile && !noBackgroundBox;

  useEffect(() => {
    const getColorDate = getDateColor(
      dateDue,
      reviewFrequency?.frequencyValue || 5,
      reviewFrequency?.frequencyKey || 'years'
    );
    setDynamicClasses(getColorDate);
  }, [props]);

  return (
    <Box
      className={clsx({
        [classes.dateColorBoxCell]: true,
        [classes.dateColorBoxWrap]: isMobile,
      })}
    >
      <Box
        data-testid="datecolorbox"
        sx={{ [boxstyle]: `${dynamicColors}!important` }}
        className={clsx({
          [classes.root]: noBackgroundBox,
          [classes.mobile]: isMobile && !boxedMobile,
          [classes.boxed]: !noBackgroundBox && !boxedMobile,
          [classes.boxedMobile]: isMobile && !noBackgroundBox,
        })}
      >
        {props.children}
      </Box>
    </Box>
  );
}
