import { OhsUser } from 'user/OhsUser';
import { ModuleUrl, ModuleRoute } from 'global-services/constants/OhsRoutes';
import { withCreatePermission, withSignOffViewPermission } from 'global-services/OhsPermission';
import { ModuleConfig } from 'global-services/constants/OhsObject';

const commonPermission = (user: OhsUser) => user?.configs?.reviewplan?.PERMISSIONS.view || false;

const createRoute = (path: string, permission: (user: OhsUser) => boolean) => ({
  path,
  url: `/${ModuleUrl.ReviewPlan}/${path}`,
  permission,
});

const OhsReviewPlanRouteList = {
  [ModuleRoute.Home]: createRoute('home', commonPermission),
  [ModuleRoute.Add]: createRoute('add', (user) =>
    withCreatePermission(user, ModuleConfig.ReviewPlan)
  ),
  [ModuleRoute.Edit]: createRoute(':reviewPlanId/edit', commonPermission),
  [ModuleRoute.Details]: createRoute(':reviewPlanId/details', commonPermission),
  [ModuleRoute.Evidence]: createRoute(':reviewPlanId/task/:taskId/completed', commonPermission),
  [ModuleRoute.SignOff]: createRoute(':reviewPlanId/task/:taskId/signoff', (user) =>
    withSignOffViewPermission(user, ModuleConfig.ReviewPlan)
  ),
};

export default OhsReviewPlanRouteList;
